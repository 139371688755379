import React, { lazy } from "react";
import { Suspense } from "react";
import { PageHeader } from "../../components/Common"
import { BreadCrumbType } from '../../datatypes/datatypes';
import { strings } from "../../services/Localization"
import { getLabelBasedOnServerType } from './../../utils/consts';


const RedocComponent = lazy(() => import("./RedocComponent"));

export class RedocPage extends React.Component<any, any> {

    breadCrumbArr: BreadCrumbType[] = [
        getLabelBasedOnServerType(strings.NAV_DEVELOPER, strings.NAV_HELP, ''),
        { label: strings.NAV_API_DOC_ITEM, url: '' }
    ];

    constructor(props) {
        super(props);
        //this.state = {};
    }

    public render() {
        return (
            <>
                <PageHeader breadCrumbArr={this.breadCrumbArr} pageButtons={[]}  />
                <div className="schema-engine-simple-page">
                    <Suspense fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
                        {<RedocComponent url="/uiapi/rest/openapi-schema?dassui=true" />}
                    </Suspense>
                </div>
            </>
        );
    }
}
