import React, { useEffect, useState, useContext } from "react";
import { strings } from "../../services/Localization";
import { checkLogin } from "../../services/Login";
import { useNavigate, useParams } from 'react-router-dom';
import { IUser } from "src/dassTypes";
import {  dialog, dialogDescription } from '../../components/Common';
import {  TAB_ADD_NOTIFICATION_POLICY, TAB_NOTIFICATION_POLICY_INFO } from '../../datatypes/tabsconstants'
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { ActionType, BulkActionType, ColumnType, DataTableOption  } from '../../components/Common/DataTable/DataTypes';
import { getAppBase, UUIDCOLWIDTH } from '../../utils/consts';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "../../utils/Toaster";
import { getVisibleActions, actionIcon, prioritizedTableColumns } from "../../utils/filters";
import{ ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT, DEFAULT_INPUT_VALIDATION }  from "../../components/Common/DataTable/DataTableConsts";
import PageContent from "../PageContent";
import { isMobile } from "react-device-detect";
import { faTrashCan,  faAdd, faRefresh, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { trustedFormatText } from "../../schemaengine/client/SchemaTextParser";



interface INotificationPolicyState {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    isRowDeleted?:boolean;
}

interface IRowType {

    notificationPolicyUuid: string;
    name: string;
    description: string;
    is_active: boolean;
    routing_rules: any;
    default_contact_points: any;
}


const NotificationPolicy: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);
    const navigate = useNavigate();
    let { id, tabname } = useParams();
    const stateInit = {
        loggedUser: AppContextObj.user,
        pageTitle: strings.NAV_MANAGE_NOTIFICATION_POLICY,
        showAlertModal: false,
        editUserId: '',
        breadCrumbArr: [{label: strings.NAV_ALERTS, url:''}, {label: strings.NAV_MANAGE_NOTIFICATION_POLICY, url:''}],
        refresh:false,
        isRowDeleted:false
    };

    const [notificationPolicyState, setNotificationPolicyState] = useState<INotificationPolicyState>(stateInit)

  

    useEffect(() => {

        if(checkLogin(AppContextObj.user)) {
            setNotificationPolicyState(prevState => { return {...prevState, loggedUser: AppContextObj.user }})
        }

    },[AppContextObj.user?.userid, notificationPolicyState.refresh])

     const detailPageNav = ( navigate, tab, name, row) => {
        
        navigate(`${getAppBase()}/notificationpolicy/${name}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/notificationpolicy`}})
    }

    const detailPageAddNav = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/notificationpolicy/${tab}`, {state: {prevPageUrl: `${getAppBase()}/notificationpolicy`}})
    }


   const getActions = () => {

        let actions: ActionType[] = [
        {
            type: "action",
            text: strings.DELETE_NOTIFICATION_POLICIES,
            visible: () => (AppContextObj?.user?.can_delete_groups && !AppContextObj?.user?._readonly),
            render: (row, title) => actionIcon(row.notificationPolicyUuid, title, faTrashAlt.iconName),
            action: (group) => deleteRule(group)
        }]


        const bulkActions: BulkActionType<IRowType>[] = [
          {
              type: "header",
              text: "Default Actions"
          },
          {
              type: "action",
              text: strings.DELETE_NOTIFICATION_POLICIES,
              render: (row, title) => actionIcon(row.notificationPolicyUuid, title, faTrashCan.iconName),
              action: (selectedIds) => deleteRuleInBulk(selectedIds),
              visible: () => { 
                  return AppContextObj.user?.account_status === "active" 
                         && !AppContextObj.user?._readonly
                         &&  AppContextObj.user?.can_register
              } 
          }];

        return {
            actions: actions,
            bulkActions:bulkActions
        }
        
    }


    const deleteRuleInBulk =  async (selectedRows: IRowType[]) => {
        const names = selectedRows.map((row) => {
            return row['name'];
        })
        const notificationPolicyUuids = selectedRows.map((row) => {
            return row['notificationPolicyUuid'];
        })
        if(notificationPolicyUuids && notificationPolicyUuids.length > 0) {
            const confirmDialogeSettings = {
                title:  strings.BULK_DELETE_CONFIRM_DIALOAG_NOTIFICATION_POLICY,
                description: await dialogDescription(names) + "",
                actionLabel: strings.ACTION_DELETE,
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(notificationPolicyUuids.map((notificationPolicyUuid) => {
                        return GenericDassQuery("/rest/notification-policies/" + notificationPolicyUuid, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable(true);
                        toast.success(strings.BULK_DELETE_NOTIFICATION_POLICY_SUCCESS_MESSAGE);
                    });
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }


   const deleteRule =  async (notificationPolicy) => {
        const confirmDialogeSettings = {
            title:  strings.DELETE_CONFIRM_DIALOG_NOTIFICATION_POLICY,
            description: notificationPolicy.name,
            actionLabel: strings.ACTION_DELETE,
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {

                Promise.resolve(GenericDassQuery("/rest/notification-policies/" + notificationPolicy.notificationPolicyUuid, { method: "DELETE" })).then((values) => {

                    refreshTable(true);
                    toast.success(strings.DELETE_NOTIFICATION_POLICY_SUCCESS_MESSAGE);

                });
               
            } catch (e) {
                toast.error(e.message);
            }
        }
    }
  





   const initDataTable = () => {
        
        const {actions, bulkActions} = getActions();

        const columns: ColumnType[] = [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 3,
                newCellWidth: "30px",
                customClass: 'sticky left-first',
            },

            {
                key: 'notificationPolicyUuid',
                type: "text",
                title: strings.TABLE_UUID,
                render: (row: IRowType) => (isMobile ? row.notificationPolicyUuid : trustedFormatText(`[[fa-ellipsis]]${row.notificationPolicyUuid.slice(-6)}`, row.notificationPolicyUuid, null, null)),
                cellWidth: 3,
                newCellWidth: (isMobile ? UUIDCOLWIDTH : '90px'),
                copyLink: true,
                customClass: 'font-monospace fa-80 sticky left-second',
                render_tooltip: (row) => row.notificationPolicyUuid,
                filterable: true,
                filterField: "search_notification_policy_uuid",
                sortable: true,
                sortKey: "sort_by_notification_policy_uuid",
                filterType: "text",
                dataAlign: "center",
                inputValidation: ID_INPUT_VALIDATION
  
            },
            {
                key: "name",
                type: "text",
                title: strings.TABLE_NAME,
                filterable: true,
                filterField: 'search_name',
                filterType: 'text',
                sortKey: "sort_by_name",
                sortable: true,
                inputValidation: ID_INPUT_VALIDATION,
                filterParams: {
                    mapper: (x) => x || undefined
                },

                detailLink: true,
                detailPageNav: (row) =>  detailPageNav(navigate, TAB_NOTIFICATION_POLICY_INFO, row.notificationPolicyUuid, row ),
                customClass: 'nowarp',
                extraClass: 'ow-datatable-overflow-ellipsis',
                newCellWidth: isMobile ? '250px' : "350px"
            },
             {
                key: "description",
                title: strings.DESCRIPTION,
                type: "text",
                filterable: true,
                filterField: "search_description",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_description",
                filterParams: {},
                cellWidth: 50,
                dataAlign:'left',
                extraClass: 'ow-datatable-overflow-ellipsis',
                render_tooltip: (row) => row.description
            }, 
            {
                key: "is_active",
                title: strings.IS_ACTIVE,
                type: "boolean",
                filterField: 'search_is_active',
                filterable: true,
                filterType: 'select',
                newCellWidth: "100px",
                filterWidth: "65px",
                filterParams: {
                    options:[
                        {label:strings.YES, value: "true"}
                        ,{label:strings.NO, value: "false"}
                    ]
                },
                render: (row) => row.is_active ? strings.YES : strings.NO,
            }
        ];

        columns.push({
            key: 'action_button',
            type: "action_button",
            title: 'Actions',
            filterable: false,
            cellWidth: 3,
            newCellWidth: "140px",
            customClass: 'sticky right',
        });


        if (isMobile) prioritizedTableColumns(['bulk_action_checkbox', 'name'], columns)

        const options:DataTableOption<IRowType> = {
            
            url:'/uiapi/rest/notification-policies',
            query_param:{ all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number:false,
            id_field:'name',
            oboe_path:'pages.*',
            available_key: 'name',
            modal:false, ///pass true to render single select radio buttons in palce of checkbox
            columns,
            actions: actions,
            defaultSortField: 'sort_by_name',
            defaultSortOrder: 'asc',
            emptyDataMsg: strings.NO_NOTIFICATION_POLICY_AVAILABLE,
            bulkActions: getVisibleActions(bulkActions)

        }

        return options;
    }
    
 

    const refreshTable = (isRowDeleted=false) => {

        setNotificationPolicyState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })
        
    }
    
   const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
                title: strings.ADD_NOTIFICATION_POLICY,
                action: () => detailPageAddNav(navigate, TAB_ADD_NOTIFICATION_POLICY ),
                type: 'button_with_icon',
                icon: faAdd,
                visible: () => { return AppContextObj?.user && AppContextObj?.user?.can_create_rules && !AppContextObj?.user?._readonly }
            },
            {
              
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            }
        ];

        return pageButtons;
    }


    return (
        <PageContent
            name="my-notification-policy" 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={notificationPolicyState.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={strings.NAV_MANAGE_NOTIFICATION_POLICY} 
            dataTableOption={initDataTable()} 
            isRowDeleted={notificationPolicyState.isRowDeleted}
            refresh={notificationPolicyState.refresh}>
        </PageContent>
    );

}


export default NotificationPolicy;
