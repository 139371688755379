import { strings } from "./../../services/Localization";

import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { faList } from '@fortawesome/free-solid-svg-icons'

import { getAppBase, getLabelBasedOnServerType } from '../../utils/consts';

import { TAB_ADD_APP, TAB_EDIT_APP, TAB_EDIT_DEFAULT_PUSH_CONFIG } from '../../datatypes/tabsconstants'

const detailPageNav = ( actionArgs ) => {
    
    const {navigate, tab, id, row}  = actionArgs;

    let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : null;

    navigate(`${getAppBase()}/my-apps/${id}/${tab}`, {state: {row: row, prevPageUrl:prevPageUrl}})
}


const MyAppDetailProps:DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        
        let id = breadCrumbArgs.hasOwnProperty('id') ? breadCrumbArgs['id'] : 0;

        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/my-apps`;
        
        const breadCrumb:BreadCrumbType[] = [
            getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, strings.NAV_APPLICATION,  prevPageUrl),
            {label: strings.NAV_APPLICATIONS_ITEM, url: prevPageUrl}, 
            {label: `${id}`, url:''}
        ]
        return breadCrumb;
    },
    getActions : (actionArgs, navigate) => {

        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';

        let row = actionArgs.hasOwnProperty('row') ? actionArgs['row'] : {};

        const accountid = row.accountid || row.id;
        
        const actions:DetailPageActionType[] =  [
            {
                type: "tab",
                text: strings.MY_APPS_ACTION_EDIT,
                icon: faList,
                key: TAB_EDIT_APP,
                action: (row) => detailPageNav({navigate, tab:TAB_EDIT_APP, id:accountid, row:row, prevPageUrl}),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/applications?get_schema=dassui,put`,
                    backUrl: prevPageUrl,
                },
                EditObject: accountid ? { accountid:accountid } : null,
            }
        ]

        return actions;
    }
}

export { MyAppDetailProps };




const navigateToAdd = ( navigate, tab, prevPageUrl) => {
    
    navigate(`${getAppBase()}/my-apps/${tab}`, {state: {tab: tab, prevPageUrl:prevPageUrl}})
}


const MyAppAddDetailProps:DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {
        
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/my-apps`;  

        const breadCrumb:BreadCrumbType[] = [
            getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, strings.NAV_APPLICATION,  prevPageUrl),
            {label: strings.NAV_APPLICATIONS_ITEM, url:prevPageUrl},
            {label: strings.APPLICATIONS_ADD, url:''}
        ]
        return breadCrumb;
    },    
    getActions : (actionArgs, navigate) => {
        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/my-apps`;

        const actions:DetailPageActionType[] =  [
            {
                type: "tab",
                text: strings.MY_APPS_ADD_APP,
                icon: faList,
                key: TAB_ADD_APP,
                action: (id) => navigateToAdd(navigate, TAB_ADD_APP, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/applications?get_schema=dassui,post`,
                    backUrl: prevPageUrl
                },
                
            },
        ]
        return actions;
    }
}


export { MyAppAddDetailProps }




const MyAppEditGlobalPushProps:DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {
        
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/my-apps`;  

        const breadCrumb:BreadCrumbType[] = [
            getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, strings.NAV_APPLICATION,  prevPageUrl),
            {label: strings.NAV_APPLICATIONS_ITEM, url:prevPageUrl},
            {label: strings.EDIT_GLOBAL_PUSH_CONFIG, url:''}
        ]
        return breadCrumb;
    },    
    getActions : (actionArgs, navigate) => {
        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/my-apps`;

        const actions:DetailPageActionType[] =  [
            {
                type: "tab",
                text: strings.EDIT_GLOBAL_PUSH_CONFIG,
                icon: faList,
                key: TAB_EDIT_DEFAULT_PUSH_CONFIG,
                action: (id) => navigateToAdd(navigate, TAB_EDIT_DEFAULT_PUSH_CONFIG, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/applications?get_schema=dassui,put,defaultpush`,
                    backUrl: prevPageUrl
                },
            }       
        ]
        return actions;
    }
}


export { MyAppEditGlobalPushProps }