/**
 * This file contains function related to embedding a custom type into the standard "element container" that
 * will add standard features such as title, description, dropzone, debug, etc.
 * 
 * This feature is generally invoked through a function in the args object.
 * 
 */
import React, { ReactNode } from "react";
import { Col, Form, Row, Accordion, Popover } from "react-bootstrap";
import Dropzone from "react-dropzone";

import { IEmbedObjectOptions, IUiSchemaElemArgs, Self, trustExpr, updateValues } from "./SchemaController";
import { HoverOverlay } from "./HoverOverlay";
import { evalExpr, getPathAndKey, verificationDescription } from "./SchemaTools";
import { IUiSchemaDropFile } from "../UiJsonSchemaTypes";




function fa(fa: string, style?: any) {
	return style ? <i className={"fa-regular " + fa + " fa-fw"} style={style}/> 
				 : <i className={"fa-regular " + fa + " fa-fw"} />;
};



export function embedObject(args: IUiSchemaElemArgs, obj: JSX.Element, options?: IEmbedObjectOptions) {

    const { elemReadOnly, readOnly, fullkey, required, uiElem, elem, title, description, helpLink, error, self } = args;

    if (uiElem?.embedding === "bypass") { return obj; }

    let   titleLayout = uiElem?.titleLayout       || args.layoutOptions?.titleLayout;
    const descLayout  = uiElem?.descriptionLayout || args.layoutOptions?.descriptionLayout;
    let marginClass = options?.noMargin !== true ? "pb-2" : "";

    // FIXME: why was this here? I have removed it but I wonder what was the purpose???
//    if (titleLayout === "none" && descLayout?.startsWith("popup")) { marginClass = ""; }

    // @Swara comment this line out.
    if (titleLayout === "in-frame") { titleLayout = "top" as any; }


    const titleElem = title ? args.stringToComponent(title) : null;
    const descriptionElem  = description ? args.stringToComponent(description) : null;
    const moreInfoText = "More Info...";
    const descElem = !helpLink ? descriptionElem :
            <>
                {descriptionElem}
                {helpLink ? 
                    self.props.helpLinkCallback 
                        ? (<><br /><a href={helpLink} onClick={(e) => { e.preventDefault(); self.props.helpLinkCallback(helpLink) }} >{moreInfoText}</a></>)
                        : (<><br /><a href={helpLink} target="_blank" rel="noopener noreferrer">{moreInfoText}</a></>) : null}
            </>;
    const helpTip = descLayout?.startsWith("popup") && descElem ? 
        (<>
            <Popover.Header>Help</Popover.Header>
            <Popover.Body>
                {descElem}
            </Popover.Body>
         </>) : null;

    const errFeedbackObj = error && error.err && (
        typeof error.err === "string" && error.err.trim()
            ? <small className="schema-engine-error-text">{fa("fa-circle-exclamation")} {args.stringToComponent(error.err.trim())}</small>
            : <Form.Control.Feedback type="invalid" />
    );

    const inputInfo = self.props.debug && <div><Form.Text>{fullkey + ": " + verificationDescription(elem)}</Form.Text></div>;

    // If the onDropFile handler is provided, we encapsulate the object in a DropFile 
    const dropZoneEvt = (innerObj: ReactNode) => {
        if (args.dropFile && !args.readOnly && obj) {
            return (
                <Dropzone noClick={args.dropFile.openFileDialogOnClick !== true} 
                    onDrop={files => onDropFile(self, files, args.dropFile, args.fullkey, args)}
                >
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {innerObj}
                            </div>
                        </section>
                    )}
                </Dropzone>
            )
        }
        return innerObj;
    }


    // If this object is a readonly and the whole schema is in readonly mode, we put an outer div with a
    // onClick() function that intercept mouse clicks and popup the highlight of the unlock button.

    const readOnlyEvt = (innerObj: JSX.Element | JSX.Element[]) => {
        if (readOnly && !elemReadOnly && self.objects.control.readOnly && !options?.isContainer) {
            return <div key={fullkey} onClick={() => self.highlightUnlock()}>{innerObj}</div>;
        }
        return innerObj;
    }


    // Construct the title object which may or may not have the description in an popover.
    const titleObj = (<>
        {titleElem}
        {required && <span style={{color:"red"}}>*</span>}
        {helpTip && <HoverOverlay
            big={descLayout==="popup-big"}
            overlay={helpTip}
        >
            <span> {fa("fa-info-circle")}</span>
        </HoverOverlay>}
    </>);


    // null; //error && error.err && <Form.Control.Feedback type="invalid">{error.err}</Form.Control.Feedback>;

    let compositElem: JSX.Element;

    if (options?.noWrapper) {

        // The noWrapper option allow us to use the embed to render basically (but including description, etc.)
        // as basic objects inside another object, e.g. like for a navbar.

        compositElem = (<React.Fragment key={fullkey}>
            {titleElem && <>{titleObj}</>}
            {descElem && descLayout === "top" && <>{descElem}</>}

            {dropZoneEvt(readOnlyEvt(obj))}
            {inputInfo}
            {errFeedbackObj}

            {descElem && descLayout === "bottom" && <>{descElem}</>}
        </React.Fragment>);

    } else if (args.type === "object" && uiElem.titleLayout == null) {		// FIXME: how to specify this

        compositElem = dropZoneEvt(
            // stack form layout put title above component on separate line

            <Accordion key={fullkey} className={"schema-engine-object-container mb-2"} defaultActiveKey={"0"} >
                <Accordion.Item	eventKey={"0"} className="schema-engine-object-container-item">
                    <Accordion.Header>
                        <div className="d-flex justify-content-between" style={{ width: "inherit" }}>
                            <div>{titleObj}</div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        {obj}
                    </Accordion.Body>
                </Accordion.Item>

                {inputInfo}
                {descElem && descLayout === "bottom" && <Form.Text>
                    <small className="new_style_help_block_color" >{descElem}</small>
                </Form.Text>}
            </Accordion>

        ) as JSX.Element;

    } else if (titleLayout === "left") {

        const titleWidth = Math.round((uiElem.titleWidthPercent != null 
                                    ? uiElem.titleWidthPercent
                                    : args.layoutOptions?.titleWidthPercent || 40) / 100 * 12);
        const bodyWidth = 12 - titleWidth;

        // Inline layout put title and component on same line
        compositElem = (
            <Form.Group key={fullkey} controlId={"label" + fullkey} as={Row}  className={"pb-2"}>
                <Form.Label column sm={titleWidth} className="text-right col-12"  >
                    {titleObj}
                </Form.Label>

                <Col sm={bodyWidth} className={options?.useFlex ? "d-flex justify-content-end align-items-center col-12" : "col-sm-" + bodyWidth}>                    {descElem && descLayout === "top" && <Form.Text>
                        <small className="new_style_help_block_color" >{descElem}</small>
                    </Form.Text>}

                    {dropZoneEvt(readOnlyEvt(obj))}
                    {inputInfo}
                    {errFeedbackObj}

                    {descElem && descLayout === "bottom" && <Form.Text>
                        <small className="new_style_help_block_color" >{descElem}</small>
                    </Form.Text>}
                </Col>

            </Form.Group>
        );

    } else if (titleLayout === "in-frame") {

        compositElem = dropZoneEvt(readOnlyEvt(
            // stack form layout put title above component on separate line
            <Form.Group key={fullkey} controlId={"label" + fullkey} className={"form-floating " + marginClass} /* validationState={error ? "error" : null}*/ >

                {descElem && descLayout === "top" && <Form.Text>
                        <small className="new_style_help_block_color" >{descElem}</small>
                </Form.Text>}
                {titleElem && <Form.Label className="text-right">{titleObj}</Form.Label>}
                {obj}
                {inputInfo}
                {errFeedbackObj}
                {descElem && descLayout === "bottom" && <Form.Text>
                    <small className="new_style_help_block_color" >{descElem}</small>
                </Form.Text>}

            </Form.Group>
        )) as JSX.Element;

    } else {

        compositElem = (
            // stack form layout put title above component on separate line
            <Form.Group key={fullkey} controlId={"label" + fullkey} className={marginClass} /* validationState={error ? "error" : null}*/ >

                {titleElem &&  titleLayout === "top"  && <Form.Label className="text-right">
                    {titleObj}
                </Form.Label>}

                {descElem && descLayout === "top" && <Form.Text>
                        <small className="new_style_help_block_color" >{descElem}</small>
                </Form.Text>}

                {dropZoneEvt(readOnlyEvt(obj))}
                {inputInfo}
                {errFeedbackObj}

                {descElem && descLayout === "bottom" && <Form.Text>
                    <small className="new_style_help_block_color" >{descElem}</small>
                </Form.Text>}

            </Form.Group>
        );
    }


    // 
    return compositElem;

}







	// When Dropfile is used, this function is called whenever the dropfile action is used.
export function onDropFile(self: Self, files: File[], dropFile: IUiSchemaDropFile, fullkey: string, args: IUiSchemaElemArgs) {

    const maxNumFiles = numberOrExpr(self, dropFile.maxNumFiles, 1, args);
    if (!files || files.length < 1) { return; }
    if (files.length > maxNumFiles) {
        // Toast error
        return;
    }

    const loadedFiles: Array<{ name: string; content: string; }> = [];
    const readers: FileReader[] = [];

    for (const file of files) {

        const name = file.name;
        const maxSize = numberOrExpr(self, dropFile.maxFileSize, 300000, args);
        if (file.size > maxSize) {
            // toast
            self.props.showMessage("error", "File is too big (max size is " + maxSize + " bytes)")
            return;
        }
        // Now let's read the file

        const reader = new FileReader();
        readers.push(reader);

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {

            loadedFiles.push({ name, content: reader.result as string });
            self.log("Finished reading file", name);

            if (loadedFiles.length === readers.length) {

                try {
                    const lib = self.lib;
                    const { key, keypath } = getPathAndKey(fullkey);
                    const objects  = args?.objects  ?? self.objects;
                    const readOnly = args?.readOnly ?? objects.control.readOnly;
                    const trust    = args?.uiElem?.trust;

                    const targetObj = evalExpr(trustExpr(trust), dropFile.onDropFile || "", lib, objects, { fullkey, value: loadedFiles, readOnly }, Error);
                    updateValues(self, targetObj, keypath, key + "");
                } catch (e) {
                    self.log(e);
                }
            }
        }
        reader.readAsDataURL(file);
    }
}





function numberOrExpr(self: Self, value: string | number | null, defaultValue: number, args: IUiSchemaElemArgs) {

    if (typeof value === "number") {
        return value;
    } else if (typeof value === "string") {
        const exprTrust = trustExpr(args?.uiElem?.trust);
        if (args) {
            const { objects, readOnly } = args;
            return evalExpr(exprTrust, value, self.lib, objects, { fullkey: args.fullkey, readOnly }, defaultValue)
        } else {
            const readOnly = !!self.objects.control.readOnly;
            return evalExpr(exprTrust, value, self.lib, self.objects, { readOnly }, defaultValue)
        }
    } else {
        return defaultValue;
    }
}
