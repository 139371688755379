import { strings } from "./../../services/Localization";
import { TAB_EDIT_RESOURCE,  TAB_VIEW_RESOURCE_SCHEMA } from '../../datatypes/tabsconstants'
import { faAdd } from '@fortawesome/free-solid-svg-icons'
import {  DetailPageActionType, DetailPagePropsType } from '../../datatypes/datatypes';
import { getAppBase, getLabelBasedOnServerType } from '../../utils/consts';


const detailPageNav = ( actionArgs ) => {

    const { navigate, tab, id, row } = actionArgs;
    let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : null;
    navigate(`${getAppBase()}/resource/${id}/${tab}`, {state: { row: row, prevPageUrl: prevPageUrl}})
}


const ResourceDetailsProps: DetailPagePropsType = {

    getBreadCrumbArr: ( breadCrumbArgs ) => {
        
        const { row } = breadCrumbArgs;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '';
        return [
            getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, '', prevPageUrl),
            { label: strings.NAV_RESOURCE_ITEM, url: prevPageUrl },
            { label: row.resource_name, url: '', comment: row.resource_uuid }
        ]
    },
    
    getActions: (actionArgs, navigate) => {

        const prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        const { row } = actionArgs;

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.EDIT_RESOURCE,
                icon: faAdd,
                key: TAB_EDIT_RESOURCE,
                action: (row: any) => detailPageNav({ navigate: navigate, tab: TAB_EDIT_RESOURCE, id: row.resource_uuid, row: row, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url:  `/rest/resources_storage?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row ? row  : null
            },
            {
                type: "tab",
                text: strings.VIEW_RESOURCE_SCHEMA,
                icon: faAdd,
                key: TAB_VIEW_RESOURCE_SCHEMA,
                action: (row) => detailPageNav({ navigate: navigate, tab: TAB_VIEW_RESOURCE_SCHEMA, id: row.resource_uuid, row: row, prevPageUrl: prevPageUrl }),
                visible: () => ((row.resource_type == "json" && row.json_schema_uuid) || row.resource_type == "data_model"),
                content: {
                    type: 'SchemaModal',
                    url: row.resource_type == "json" ? `/rest/resources_data/${row.json_uuid_token}` 
                                            : `/rest/resources_data/${row.token}`,
                    backUrl: prevPageUrl
                },
                EditObject: row ? row  : null
            },
        ]
        return actions;
    }
}

export  default ResourceDetailsProps