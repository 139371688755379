import React, { Suspense, lazy } from "react";
import ReactDOMServer from 'react-dom/server';


import { IMarkdownProps } from "./MarkdownComponent";
import { IUiSchemaElemArgs } from "./SchemaController";
import { registerComponentHandler, registerExtensionComponent, registerJsonSchema } from "./SchemaExtensions";
import { readValue, vegaLikeGetInfo, vegaLikeGetSchema } from "./VegaLikeTools";
import { MarkdownSchema, markdownJsonSchema } from "./ExtMarkdownTypes";


const Markdown = lazy(() => import("./MarkdownComponent"));


export default function LazyMarkdown(props: IMarkdownProps) {

    return <Suspense fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
                <Markdown markdown={props.markdown}/>
            </Suspense>;
}

registerJsonSchema("internal:markdown", ["*"], markdownJsonSchema);







const markdownExtension = (args: IUiSchemaElemArgs) => {

	const { value, fullkey } = args;
    let markdown = "";
    const { debug, isSchema } = vegaLikeGetInfo(value);

    try {
        if (typeof value === "string") {
            markdown = value;

        } else if (isSchema && value.$schema.includes("markdown")) {

            const schema = vegaLikeGetSchema<MarkdownSchema>(value);
            const md = readValue(schema, schema.markdown, "string", 0);
            const elem = args.stringToComponent(md);
            markdown = ReactDOMServer.renderToStaticMarkup(elem);

        }
    } catch (e) {
        if (debug) { console.log(e); }
        markdown = debug ? e.message : "";
    }

	return args.embedObject(<LazyMarkdown key={fullkey} markdown={markdown} />);
};

registerComponentHandler("markdown", markdownExtension);
registerExtensionComponent("Markdown", LazyMarkdown);
