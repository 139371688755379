import { IJsonSchemaObject } from "../UiJsonSchemaTypes";
import { Value, VegaLikeSchemaBase, vegaLikeJsonSchema } from "./VegaLikeToolsTypes";


export const markdownJsonSchemaPreamble = "The markdown card is used to show text in markdown format. The markdown support embedded Mermaid charts, and Katex formulas. " +
    "The JSON schema for the definition is shown below. Be sure " +
    'to set the $schema field to "internal:markdown" when creating the JSON definition.';

export interface MarkdownSchema extends VegaLikeSchemaBase {
    markdown: Value;
}



export const markdownJsonSchema: IJsonSchemaObject = {
	$id: "internal:markdown",
	$schema: "http://json-schema.org/draft-07/schema",
	type: "object",
	properties: {

        $schema: { type: "string", const: "internal:markdown" },
        ...vegaLikeJsonSchema.properties,

        markdown: {
            description: `Markdown text to be shown`,
            type: "string",
            maxLength: 20000
        },

	},
	required: ["$schema"],
    additionalProperties: false,
}
