/**
 * layout engine to layout the components inside a card, or of an object
 */
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IElemNode, IColumnElem, Self } from "./SchemaController";
import { IUiSchemaCardOptions } from "../UiJsonSchemaTypes";


function addRowWithColumns(numColumns: number, columnsElems: IColumnElem[]) {

    if (numColumns === -1) {
        return <div className="col-lg-80 col-md-auto col-xs-12 d-flex align-items-start flex-wrap">
            {columnsElems.map(colElem => <div className="ms-1" key={(colElem.elem as JSX.Element).key + "_col"}>{colElem.elem}</div>)}
        </div>
    }

    const cols: JSX.Element[] = [];
    const size = 12 / numColumns;
    let   pos = 0;
    let   key = "";

    for (const colElem of columnsElems) {
        key = key || (colElem.elem as JSX.Element).key + "_row";
        const colSize = size * (colElem.options.width || 1);
        const cl = pos + colSize < 12 ? "border-end" : "";

        cols.push(
            <Col className={cl} key={(colElem.elem as JSX.Element).key + "_col"} sm={colSize}>
                {colElem.elem}
            </Col>
        )
        pos += colSize;
    }
    key = key || "keyrow";

    return <Row key={key}>{cols}</Row>;
}



export function schemaElemColLayout(self: Self, cardProps: IUiSchemaCardOptions, elemNodesRaw: IElemNode[], jsxElems: JSX.Element[], columns: number, grp = false) {

    let elemNodes: IElemNode[] = elemNodesRaw;

    /**
     * first let's collect the groups
     */
    if (!grp) {
        elemNodes = [];
        const groups: { [group: string]: IElemNode[] } = {};
        const groupsJsxElems: { [group: string]: JSX.Element[] } = {};

        for (const en of elemNodesRaw) {
            if (en.args.uiElem?.group) {

                const grpid = en.args.uiElem?.group;
                if (!groups[grpid]) {
                    
                    groups[grpid] = [];
                    groupsJsxElems[grpid] = [];


//                    const trust = cardProps.trust;
                    const groupProps = cardProps?.groups?.[grpid];
                    const title = groupProps?.title;
                    const titleObj = title ? en.args.stringToComponent(title) : null;


                    elemNodes.push({
                        args: { ...en.args, uiElem: {} },
                        jsxElem:
                            (<Card key={"grp-" + en.args.fullkey} className="schema-engine-group-card mb-2">
                                {titleObj && <Card.Header>{titleObj}</Card.Header>}
                                <Card.Body>{groupsJsxElems[en.args.uiElem?.group]}</Card.Body>
                            </Card>)
                    });

                }
                groups[en.args.uiElem?.group].push(en);

            } else {
                elemNodes.push(en);
            }
        }

        for (const grpKey of Object.keys(groups)) {
            schemaElemColLayout(self, cardProps, groups[grpKey], groupsJsxElems[grpKey], 0, true);
        }
    }


    if (columns) {
        const colElems: IColumnElem[] = [];

        let lastColBehaviour = null;
        for (const {args, jsxElem} of elemNodes) {
            const uiElem = args.uiElem;

            const colBehaviour = uiElem.colBehaviour || "normal";
            const width = uiElem.colWidth ?? 1;
            const pos   = colElems.reduce((sum, cv) => sum + cv.options.width, 0);

            if (colElems.length > 0 && (colBehaviour === "alone" || colBehaviour === "break-before" || colBehaviour === "fullwidth" || pos + width > columns 
                                        || ((colBehaviour === "stack-line" || lastColBehaviour === "stack-line") && lastColBehaviour !== colBehaviour))) {

                jsxElems.push(addRowWithColumns(colBehaviour === "stack-line" ? -1 : columns, colElems));
                colElems.splice(0, colElems.length);
            }
            colElems.push({ options: { width }, elem: jsxElem});
    
            if (pos + width >= columns || colBehaviour === "fullwidth" || colBehaviour === "break-after" || colBehaviour === "alone") {
                jsxElems.push(addRowWithColumns(colBehaviour === "fullwidth" ? 1 : columns, colElems));
                colElems.splice(0, colElems.length);
            }

            lastColBehaviour = colBehaviour;
        }

        if (colElems.length > 0) {
            jsxElems.push(addRowWithColumns(lastColBehaviour === "stack-line" ? -1 :  columns, colElems));
            colElems.splice(0, colElems.length);
        }
    
    } else {
        for (const {jsxElem} of elemNodes) {
            jsxElems.push(jsxElem as JSX.Element);
        }
    }
}
