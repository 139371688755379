import React, {useContext, useLayoutEffect} from "react";
//import ReactDOM from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHome } from '@fortawesome/free-solid-svg-icons';

import { BreadCrumbType } from '../../datatypes/datatypes';

import { Dropdown } from 'react-bootstrap';

import AppContext from '../../context/AppContext'
import { DataTableContext } from "./DataTable/DataTableState";
import { DataTableChildContext } from "./DataTable/DataTableChildState";

import { BulkActionType } from "./DataTable/DataTypes";
import { strings } from "../../services/Localization";

interface BreadcrumbComponentProps {
    breadCrumbArr: BreadCrumbType[];
    showMain?:() =>  void;
    countLabel?: string;
    name?:string;
    isChildTable?: boolean | undefined;
}

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = ( props ) => {
    const { isChildTable } = props;
    const AppContextObj = useContext(AppContext);
    let DataTableContextObj = useContext(DataTableContext);

    if(isChildTable) {
        DataTableContextObj = useContext(DataTableChildContext);
    } else {
        DataTableContextObj = useContext(DataTableContext);
    }
    var len = (props.breadCrumbArr.length - 1);
    const navigate = useNavigate();
    useLayoutEffect(() => {
        
        AppContextObj.setCountLabel(props.countLabel)

    },[props.countLabel])
    const { id, tabname  } =  useParams();
    const clearSelection = () => {
        DataTableContextObj.setSearchState(prev => {
            return {...prev, selectedRowIds :[] }
        })  
    }
    let recordCounterId = (props.name) ? props.name : 'data-table';
    const isNst = (String(location.pathname).indexOf('/nst'));
    const redirection = isNst != -1 ? "/nst/network_map" : "/app";
    const selectedRowIds = DataTableContextObj.searchState.selectedRowIds;
       
    const isDisplaySelectedRowsData = () => {
        if(DataTableContextObj.searchState?.allowBulkActions && 
             DataTableContextObj.searchState?.dataTableOption?.bulkActions?.length > 0 && 
             ((!id && !tabname) || (tabname &&  tabname == "view-data" || tabname == "job-details" || tabname == "group-activity"))){
            return true;
        }
        return false;
    }
    const hideBulkActions = DataTableContextObj.searchState.dataTableOption.hideBulkActions;
    const totalData = DataTableContextObj.searchState.dataTableOption.data;
            //below code will execute when there are no records but pagestate is there.
            return (
        <div className="d-flex flex-wrap align-items-center flex-sm-row justify-content-start align-items-start" style={{ paddingTop:'10'}}>
            <div className="breadcrumb">
                <div className="active cursor-pointer" style={{cursor: "pointer"}}>
                    <FontAwesomeIcon icon={faHome} onClick={() => navigate(redirection)}/>
                </div>
                {props.breadCrumbArr.map(function(item, index) {
                    if((len ===  index && AppContextObj.pageState.totalRecords > 0 && props.countLabel) || (!item.label)  ) {
                        return null
                        // return (<BreadcrumbComponentItem item={item} index={index}  key={index} child={<small>({AppContextObj.pageState.totalRecords} {props.countLabel})</small>} />)
                    } else {
                        return (<BreadcrumbComponentItem item={item} index={index}  key={index}  selectedRowIds={selectedRowIds} />)
                    }
                })}
            </div>
            <div className="d-flex flex-wrap align-items-center">
                {totalData?.length > 0 && (location.pathname == '/app/mydevice-register' || location.pathname == '/app/device-import') ?
                <div className="ms-2">
                    <small><span className="text-info">{strings.TABLE_COUNT_ALL}</span> ({totalData ? totalData?.length : 0})</small>              
                </div> : 
                <div className="ms-2" id={`${recordCounterId}-record-count`}></div>}
                {isDisplaySelectedRowsData() &&  selectedRowIds && selectedRowIds.length > 0 &&
                <>
                    <div className="ms-2"><small>|&nbsp;
                        <span className="text-info">{strings.TABLE_COUNT_SELECTED}</span> ({selectedRowIds?.length || 0}) &nbsp;| &nbsp; 
                        <a style={{cursor : "pointer"}} className="btn-link text-info" onClick={clearSelection}>{strings.TABLE_BUTTON_CLEAR_SELECTED}</a>
                    </small>
                    </div>
                    {!hideBulkActions &&
                    <div className="ms-2 custom-dropdown d-flex">
                        |&nbsp;
                        <RenderBulkActionDropDown 
                            bulkActions={DataTableContextObj.searchState.dataTableOption.bulkActions} 
                            DataTableContextObj={DataTableContextObj}
                            selectedRowsData={selectedRowIds}
                        />
                    </div>
                    }
                </>
            }
            <div style={{marginLeft:'5px', marginTop: '8px'}}>
                <div className="react-spinner"></div>
            </div>
            </div>
            
        </div>
    )
}

    interface RenderFontAwesomeIconProps {
        icon: any;
        size?: any;
        color?: any;
    }

    const RenderFontAwesomeIcon: React.FC<RenderFontAwesomeIconProps> = ( props ) => {
        try {
            let size = (props.size) ? props.size : 'sm';
            if(props.icon.prefix != null) {
                return (<FontAwesomeIcon icon={props.icon} size={size} />)
            }else {
                return null;
            }
            
        }catch (e) {
            return null;
        }
    }


    interface RenderBulkActionDropDownProps {
        bulkActions?: BulkActionType[];
        DataTableContextObj: any;
        selectedRowsData?: any[];
    }

    const RenderBulkActionDropDown: React.FC<RenderBulkActionDropDownProps> = React.memo(({ bulkActions, DataTableContextObj, selectedRowsData }) => {
        // ToDo: I have write the logic to make union of group and devices service, I think this will done when event/stream implmeneted on bulk device services execution
        if (bulkActions && bulkActions.length > 0) {
            let addServiceHeader : "yes" | "no" | "added" = "no";
            const list = bulkActions.map(function (action: BulkActionType, index: number) {
                let row = null;
                let serviceIndex = -1;
                if (addServiceHeader === "yes") {
                    addServiceHeader = "added";
                }
                let serviceName = ""
                if(action.render && action.text && selectedRowsData && selectedRowsData.length > 0) {
    
                    let outFromLoop = false;
                    for(let i=0;i<selectedRowsData.length;i++) {
                        if(!selectedRowsData[i].suspended) {
                            const services = selectedRowsData[i].services
                            if(services) {
                                for(let j = 0; j < services.length; j++) {
                                    if(services[j].name == action.text) {
                                        if (addServiceHeader === "no") {
                                            addServiceHeader = "yes";
                                        } 
                                        serviceName = services[j].name
                                        row = selectedRowsData[i];
                                        serviceIndex = j;
                                        outFromLoop = true
                                        break;             
                                    }
                                }
                            }
                            if(outFromLoop) break;
                        }
                    }
                }
                if (action.type === 'action' && (((action.hasOwnProperty('visible') && action.visible(row, serviceIndex, serviceName) === true) || action.hasOwnProperty('visible') === false))) {
                    if(action.hasOwnProperty('visible') === false && action.hasOwnProperty('bulkActionVisible')) {
                        if(!action.bulkActionVisible(selectedRowsData)) return null;
                    }
                    return (<React.Fragment key={`action-${index}`}>
                        { addServiceHeader == "yes" &&
                        <Dropdown.Header key={`header-${index}`}>{ strings.TABLE_BUTTON_BULK_SERVICE_ACTIONS }</Dropdown.Header>
                        }
                        {<Dropdown.Item
                            disabled={selectedRowsData && selectedRowsData.length > 0 ? false : true}
                            key={index}
                            eventKey={index}
                            onClick={() => {
                                if (action.action) {
                                    return action.action(selectedRowsData, serviceIndex);
                                }
                            }}>
                            {(action.render && row) ? (
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }}>
                                    {<div style={{ marginRight: 5, marginLeft: 5 }}>{action.render(row, action.text, serviceIndex, serviceName)}</div>}
                                </div>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }}>
                                    {action.icon && <div style={{ marginRight: 5, marginLeft: 5 }}><RenderFontAwesomeIcon icon={action.icon} /></div>}
                                    {action.text}
                                </div>
                            )}
                        </Dropdown.Item>}</React.Fragment>)
                } else if (action.type === 'separator') {
                    return (<Dropdown.Divider key={index} />)
                } else if (action.type === "header") {
                    return (
                    <Dropdown.Header key={index}>{action.text}</Dropdown.Header>)
                } else {
                    return null;
                } 
            })   

            return (
                <Dropdown   autoClose="outside">
                    <Dropdown.Toggle variant="dark" size="sm" id="bulk-action-dropdown-components">
                        {strings.TABLE_BUTTON_BULK_ACTIONS} 
                    </Dropdown.Toggle>
                    <Dropdown.Menu  align="end" title="Dropdown end" id="dropdown-menu-align-end" className="breadcrumb-dropdown-menu">
                        {list}
                    </Dropdown.Menu>
                </Dropdown>
            )

        }else {
            return (<div>Actions</div>);
        }
    
    })

interface BreadcrumbComponentItemProps {
    item:any;
    index:number;
    child?:JSX.Element;
    selectedRowIds?: any;
}

const BreadcrumbComponentItem: React.FC<BreadcrumbComponentItemProps> = ( props ) => {
    let { item , index, selectedRowIds } = props;
    if (!selectedRowIds) selectedRowIds = [];

    const navigate = useNavigate();
    
    let nav =  (item.url) ? (item.url === '-1') ? () => { navigate(-1) } : () => { navigate(item.url,{replace: false}) } : () => {};
    

    return (
        <div onClick={  nav  } style={{cursor: 'pointer'}} className="active cursor-pointer" key={index}>
            {item.label} 
            {props.child}
            {(item.comment && selectedRowIds.length == 0) && <small>{item.comment}</small>} 
        </div>
    )
}

export { BreadcrumbComponent };


