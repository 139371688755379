import { IJsonSchemaObject } from "../UiJsonSchemaTypes";
import { Value, VegaLikeSchemaBase, vegaLikeJsonSchema } from "./VegaLikeToolsTypes";


export const mapJsonSchemaPreamble =
'The map visualization or card, can show individual points, paths or images on a (geographical) map. ' +
'Be sure to set the $schema field to "internal:map" when creating the JSON definition.' + 
'The map definition object typically looks like this:\n\n' +
'{\n' +
'  "$schema": "internal:map",\n' +
'  "mark": {...},\n' +
'  "encoding": {...}\n' +
'}\n\n' +
'The full JSON Schema definition is shown below:\n\n';

interface MapSchemaBase extends VegaLikeSchemaBase {
	$schema: "internal:map";

	layer: (MapSchemaPathLayer | MapSchemaPointLayer | MapSchemaImageLayer)[];
	config: {
		mouseScrollWheelZoom?: Value;
		maxZoom?: Value;
		zoom?: number;
		center?: [Value, Value];
	}
}

export interface MapSchemaPointLayer {
	mark: "point" | { 
        type: "point",
        cluster?: Value;
    };
	encoding: {
		latitude?: Value;
		longitude?: Value;
        color?: Value;
		legend?: Value;
	}
}


export interface MapSchemaPathLayer {
	mark: "path" | { type: "path" };
	encoding: {
		latitude?: Value;
		longitude?: Value;
		color?: Value;
		legend?: Value;
	}
}

export interface MapSchemaImageLayer {
	mark: "image" | { type: "image" };
	encoding: {
		url: Value;
		opacity: Value;
		bounds: [number, number][];
	}
}

export type MapSchema = MapSchemaBase & (MapSchemaPointLayer | MapSchemaPathLayer | MapSchemaImageLayer);



const pointLayerEncoding: IJsonSchemaObject = {
    type: "object",
    description: "Encoding object for a map point. Define how the parameters for a point are provided.",
    properties: {
        latitude: {
            description: "Latitude of Point",
            $ref: "#/$defs/numberRefExprDef",
        },
        longitude: {
            description: "Longitude of Point",
            $ref: "#/$defs/numberRefExprDef",
        },
        color: {
            description: "Color of the point icon. Format is any web-color, or the #rrggbb color codes.",
            $ref: "#/$defs/stringRefExprDef",
        },
        legend: {
            description: "Text for the Point",
            $ref: "#/$defs/stringRefExprDef",
        },
    },
    required: ["latitude", "longitude"],
    additionalProperties: false,
}



const pathLayerEncoding: IJsonSchemaObject = {
    type: "object",
    description: "Encoding object for a path. Define how the parameters for a path are provided.",
    properties: {
        latitude: {
            description: "Latitude of each path element",
            $ref: "#/$defs/numberRefExprDef",
        },
        longitude: {
            description: "Longitude of each path element",
            $ref: "#/$defs/numberRefExprDef",
        },
        color: {
            description: "Color of each path",
            $ref: "#/$defs/stringRefExprDef",
        },
        legend: {
            description: "Text for the path",
            $ref: "#/$defs/stringRefExprDef",
        },
    },
    required: ["latitude", "longitude"],
    additionalProperties: false,
}




const imageLayerEncoding: IJsonSchemaObject = {
    type: "object",
    description: "Encoding object for image overlay. Define how the parameters for an image overlay are provided.",
    properties: {
        url: {
            description: "URL of image to be used as overlay",
            $ref: "#/$defs/stringRefExprDef",
        },
        opacity: {
            description: "Opacity of overlaid image. 0 is fully transparent, 1 is fully opaque",
            $ref: "#/$defs/numberRefExprDef",
        },
        bounds: {
            description: `Array of three [latitude,longitude] coordinates. The coordinates correspond to corners of the image.
The first coordinate is the top-left corner, second coordinate is the top-right corner, and the third is the bottom-left corner.`,
            type: "array", minItems: 3, maxItems: 3,
            items: {
                type: "array", minItems: 2, maxItems: 2,
                items: {
                    type: "number",
                }
            }
        }
    },
    required: ["url", "bounds"],
    additionalProperties: false,
}


const pointLayer: IJsonSchemaObject = {
    type: "object",
    properties: {
        mark: {
            oneOf: [
                { type: "string", const: "point" },
                { type: "object", properties: {
                    type: { type: "string", const: "point" },
                    cluster: {
                        description: "Enable clustering of the points on the map when a low zoom factor make the markers come too close together.",
                        $ref: "#/$defs/booleanRefExprDef"
                    }
                }, required: ["type"], additionalProperties: false },
            ]
        },
        encoding: { $ref: "#/$defs/pointLayerEncoding" }
    },
    required: ["mark", "encoding"],
}

const pathLayer: IJsonSchemaObject = {
    type: "object",
    properties: {
        mark: { 
            oneOf: [
                { type: "string", const: "path" },
                { type: "object", properties: { 
                    type: { type: "string", const: "path" },
                }, required: ["type"], additionalProperties: false },
            ]
        },
        encoding: { $ref: "#/$defs/pathLayerEncoding" }
    },
    required: ["mark", "encoding"],
}

const imageLayer: IJsonSchemaObject = {
    type: "object",
    properties: {
        mark: { 
            oneOf: [
                { type: "string", const: "image" },
                { type: "object", properties: { 
                    type: { type: "string", const: "image" }
                }, required: ["type"], additionalProperties: false },
            ]
        },
        encoding: { $ref: "#/$defs/imageLayerEncoding" }
    },
    required: ["mark", "encoding"],
}


const mark: IJsonSchemaObject = {

    description: `[[@md]]Mark define the type of visualization to place on top of the map. Three types are supported:

* point - A location pointer is set for each location
* path - A line path is draw between each location. It is possible to have several path in same view using the color encoding field to associate locations with a path.
* image - Overlay an image on top of the map.`,

    oneOf: [
        { type: "string", enum: ["point", "path", "image"] },
        { type: "object", properties: { type: { 
            description: "please set mark type to one of point, path or image",
            type: "string",  enum: ["point", "path", "image"] }}, required: ["type"] 
        },
    ]

}



export const mapJsonSchema: IJsonSchemaObject = {
	$id: "internal:map",
	$schema: "http://json-schema.org/draft-07/schema",
    $defs: {
        ...vegaLikeJsonSchema.$defs,

        pointLayerEncoding,
        pathLayerEncoding,
        imageLayerEncoding,

        pointLayer,
        pathLayer,
        imageLayer,

        mark,

    },

	type: "object",
	properties: {

        ...vegaLikeJsonSchema.properties,


		config: {
			description: "The config block contain map settings that apply to the map itself, such as zoom factor, center, etc.",
            type: "object",
			properties: {
				mouseScrollWheelZoom: {
                    description: "Determine if the scroll wheel of the mouse should zoom the map",
                    $ref: "#/$defs/booleanRefExprDef",
                },

                maxZoom: {
                    description: "Maximum allowed zoom factor on the map.",
                    $ref: "#/$defs/numberRefExprDef",
                },

                zoom: {
                    description: "Current zoom level on map",
                    type: "integer",
                },

                center: {
                    description: "Map center [latitude, longitude]",
                    type: "array", minItems: 2, maxItems: 2,
                    items: {
                        type: "number"
                    }
                },
			},
			required: [],
            additionalProperties: false,
		},


        layer: {
            description: `The layer array can be used when the map need to contain more than one layer of data`,
            type: "array",
            items: {
                type: "object",
                properties: {
                    mark: { $ref: "#/$defs/mark" },
                },
                required: ["mark"],
                oneOf: [
                    { $ref: "#/$defs/pointLayer" },
                    { $ref: "#/$defs/pathLayer"  },
                    { $ref: "#/$defs/imageLayer" },
                ]
            }
        },

        mark: { $ref: "#/$defs/mark" },
        encoding: { type: "object" },
    },

    oneOf: [{
        required: ["layer"]
    }, {
        oneOf: [
            { $ref: "#/$defs/pointLayer" },
            { $ref: "#/$defs/pathLayer"  },
            { $ref: "#/$defs/imageLayer" },
        ],   
    }],

	required: [],
    additionalProperties: false,
}

