import React, {useEffect, useState, useContext} from "react";
import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { GenericDassQuery } from "../../services/BasicDassQueries";

import { appBaseUrl, getAppBase, getLabelBasedOnServerType } from '../../utils/consts';

declare const constants;

import { IUser } from "src/dassTypes";

import { faRefresh } from '@fortawesome/free-solid-svg-icons'

import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import  { dateTimeString } from '../../utils/filters';

import { ActionType, BulkActionType, ColumnType, DataTableOption } from '../../components/Common/DataTable/DataTypes';
import { DEFAULT_RECORD_LIMIT } from "../../components/Common/DataTable/DataTableConsts";
import { toast } from "./../../utils/Toaster";
import PageContent from "../PageContent";


interface IEventLogStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editUserId: string | null;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    loglevelstatus: string;
}

const EventLog: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);

    
    

    const stateInit = {
        loggedUser: AppContextObj.user,
        pageTitle: strings.EVENT_LOG_PAGE_TITLE,
        showAlertModal: false,
        editUserId:'',
        breadCrumbArr: [getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, strings.NAV_APPLICATIONS_ITEM, `${getAppBase()}/my-apps`), 
                        {label: strings.EVENT_LOG_NAV_TITLE, url:''}, {label: "", url: ''} ],
        refresh:false,
        loglevelstatus: "info"
    };
    
    const [state, setState] = useState<IEventLogStates>(stateInit);


    useEffect(() => {

        let loglevel // Fixme the value in undefined as load 

        async function init ()  {
            const loglevel_data = await GenericDassQuery("/rest/pushmode/loglevel", { method: "GET"})
            if ( loglevel_data.status === 200 && loglevel_data.data ) {
                loglevel = loglevel_data.data.log_level
                setState(prevState => {return {...prevState, loggedUser: AppContextObj.user, loglevelstatus: loglevel} })
            }
        }

        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            //LoggedIn();
            if(checkLogin(AppContextObj.user)) {
                init()
            } 
        }
    },[AppContextObj.user?.userid])

    
    const setloglevel = async (duration : number, mode : string) => {

        if(duration >= 0 && mode){
            try {
                await GenericDassQuery("/rest/pushmode/loglevel?log_level=" + mode + "&duration_in_hours=" + duration, { method: "PUT" });
                setState(prevState => {return {...prevState, loggedUser: AppContextObj.user, loglevelstatus: mode} })
                toast.success(` Log Level set to ${mode}`)
                refreshTable()
                }
            catch(e) {
                toast.error(e.message)
                refreshTable()
            }
        }

    }


    

    const getActions = () => {
        
        const bulkActions: BulkActionType[] = [];
        const actions: ActionType[] = [];

        return { actions, bulkActions };
    }

 

    const initDataTable = () => {
		
        const actions = getActions();

		const columns:ColumnType[] = [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 2,
                newCellWidth: "2%"
            },{
			key: "ts",
			title: strings.EVENT_LOGS_TABLE_COL_TIMESTAMP,
			type: "text",
			filterable: true,
			filterField: "type",
            filterType: 'daterange',
            filterParams: {
                startField: "from_date",
                endField: "to_date",
                mapper: (x) => x && x.format()
            },
			sortable: true,
			sortKey: "sort_by_timestamp",
            render: (row) => dateTimeString(row.ts),
			newCellWidth: "100px"
		}, {
			key: "evt",
			title: strings.EVENT_LOGS_TABLE_COL_EVENT,
			type: "text",
			filterable: false,
            filterType: 'text',
            filterField: 'evt',
            newCellWidth: "100px"
		}, 
        {
			key: "app",
			title: strings.EVENT_LOGS_TABLE_COL_APPLICATION,
			type: "text",
			filterable: true,
            filterType: 'text',
			filterField: 'search_application',
            inputValidation: "^([a-zA-Z0-9\\s\\-\\.\\_!&|])*$",
            newCellWidth: "120px"
		},
        (constants.server_type === "dmp" ? {
			key: "deviceUuid",
			title: strings.DEVICE_UUID,
			type: "text",
			filterable: false,
            filterField: 'deviceuuid',
            newCellWidth: "120px"
		} : {
			key: "deveui",
			title: strings.DEVEUI,
			type: "text",
			filterable: false,
            filterField: 'deveui',
            newCellWidth: "120px"
        }),
		{
			key: "txt",
			title: strings.EVENT_LOGS_TABLE_COL_TEXT,
			type: "text",
			filterable: false,
            filterType: 'text',
			filterField: 'txt',
            newCellWidth: "80px"
		}
        ];

        const options: DataTableOption = {
            url: '/uiapi/rest/pushmode/log',
            query_param: { get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress', all: true },
            id_field:'deveui',
            oboe_path:'pages.*',
            available_key: 'deveui',
            columns,
            serial_number: false,
            ...actions,
            allowBulkActions: false,
        }

        return options;
	}

    
    const refreshTable = () => {
        
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
    }
    
    const getPageButtons = () => {

        const pageButtons: PageButtonType[] = [

            {
                title: strings.EVENT_LOGS_TABLE_LOG_LEVEL + ": " + state.loglevelstatus, // Fixme 
                type: 'dropdown',
                visible: () => !state.loggedUser?._readonly,
                dropDownItems: [
                    { title: "Error", action:() => setloglevel( 0 , 'error')},
                    { title: "Info: 1 Day", action:() => setloglevel( 24 , 'info')},
                    { title: "Info: 3 Day", action:() => setloglevel( 72 , 'info')},
                    { title: "Info: 1 Week", action:() => setloglevel( 168 , 'info')},
                    { title: "Info: 2 Weeks", action:() => setloglevel( 336 , 'info')},
                    { title: "Debug: 1 Day", action:() => setloglevel( 24 , 'debug')},
                    { title: "Debug: 3 Day", action:() => setloglevel( 72 , 'debug')},
                    { title: "Debug: 1 Week", action:() => setloglevel( 168 , 'debug')},
                    { title: "Debug: 2 Weeks", action:() => setloglevel( 336 , 'debug')},
                ]
            },
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh

            },
            
        ]

        return pageButtons;
    }
        
    return (
        <PageContent
            name="event-logs"
            actions={getActions()} 
            breadCrumbArr={state.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={`Events`} 
            dataTableOption={initDataTable()} 
            refresh={state.refresh}>
        </PageContent>
    );

}

export default EventLog;
