// tslint:disable:max-line-length
import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { Modal, Container } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

import stripJsonComments from "strip-json-comments";
import { GenericDassQuery, IRequestMethod, IRequestReturnData } from "../../services/BasicDassQueries";

import { toast } from "../../utils/Toaster";
import { strings } from "../../services/Localization";

import { IJsonSchemaObject } from "../../schemaengine/UiJsonSchemaTypes";
import SchemaController, { IGetResourcesOptions, ISchemaLocaleDictionary, IUiSchemaUpdateState, SchemaControllerRef } from "../../schemaengine/client/SchemaController";
import { setTranslationHandler } from "../../schemaengine/client/SchemaTranslationAudit";
import { registerExtensionLayoutComponent, registerExtensionLibFunction, registerExtensionTextMarkerHandler } from "../../schemaengine/client/SchemaExtensions";


// The good old status lights
import yellowLight from "../../../resources/images/yellow_light.png";
import redLight from "../../../resources/images/red_light.png";
import greenLight from "../../../resources/images/green_light.png";
import amberLight from "../../../resources/images/amber_light.png";

import AppContext, { IDassUiAppContext } from '../../context/AppContext'

// Import for map extension
import "./SchemaModal.css";


import { ipadWidth, windowWidth } from '../../utils/consts';

import { dialog } from "../Common/ConfirmDialog";
import { faInfo } from "@fortawesome/pro-regular-svg-icons";


// Standard SchemaModal extensions
import "../../schemaengine/client/ExtCarousel";
import "../../schemaengine/client/ExtMapView";
import "../../schemaengine/client/ExtChartJs";
import "../../schemaengine/client/ExtAmChart";
import "../../schemaengine/client/ExtVegaChart";
import "../../schemaengine/client/ExtColorPicker";
import "../../schemaengine/client/ExtProgressBar";
import "../../schemaengine/client/ExtMarkdown"
import "../../schemaengine/client/ExtNavbar"

import "../../schemaengine/client/ExtSchemaFormAceEdit";
import "../../schemaengine/client/ExtSchemaFormMonacoEdit";

import "../../schemaengine/client/SchemaLayoutGridStack";
import "../../schemaengine/client/ExtDateRangePicker";
import "../../schemaengine/client/ExtJsonObjectInspector";
import "../../schemaengine/client/ExtAgGrid";
import "../../schemaengine/client/ExtDashboardStatus";
import "../../schemaengine/client/ExtDashboardSelect";
import "../../schemaengine/client/ExtVisTimeline";
import "../../schemaengine/client/ExtMapSchema";

// FIXME: since masonry is no longer working with react 18, we simply for the moment use the stack layout in place of masonry.
import SchemaLayout from "../../schemaengine/client/SchemaLayout";

registerExtensionLayoutComponent("masonry", SchemaLayout);



// Dedicated extensions
import "./RadioConfig";
import "./PageTable";

import { BreadCrumbType } from "src/datatypes/datatypes";
import { BreadcrumbComponent } from "../Common/BreadcrumbComponent";
// import ScrollButton from "../ScrollButton";



const testPrefix = ""; // "/uitest";       // Note NEVER commit with this enabled.

export interface ISchemaModalHooks {
    updateValues?: (obj: any) => void;
}



interface ISchemaModalProps {
    Schema?: IJsonSchemaObject;
    SchemaUrl?: string;
    EditObject?: any;
    editMode?: boolean;
    breadCrumbArr?: BreadCrumbType[];
    loadDataOnOpen?: boolean;           // if set, data resources with onOpenOnLoadRequest will be loaded
    warningFooter?: boolean;            // ?? should be removed
    OnClose?: (obj: any, refreshRequired: boolean) => void;
    OnChange?: (currentObject: any, lastObject: any) => void;
    type: "modal" | "page" | "navbar" | "page-fluid";

    hooks?: ISchemaModalHooks;
}





// Register the "standard" text handlers that are defined as standard
const fa = (fa: string, key: string, style: any) => {
    return <i key={key} className={"fa-regular " + fa + " fa-fw"} style={style} />;
};

registerExtensionTextMarkerHandler("ok",       (key, style) => [fa("fa-circle-check", key, style)]);
registerExtensionTextMarkerHandler("warning",  (key, style) => [fa("fa-circle-exclamation", key, style)]);
registerExtensionTextMarkerHandler("error",    (key, style) => [fa("fa-circle-xmark", key, style)]);
registerExtensionTextMarkerHandler("cog",      (key, style) => [fa("fa-cog", key, style)]);
registerExtensionTextMarkerHandler("copy",     (key, style) => [fa("fa-copy", key, style)]);
registerExtensionTextMarkerHandler("download", (key, style) => [fa("fa-download", key, style)]);
registerExtensionTextMarkerHandler("trash",    (key, style) => [fa("fa-trash-alt", key, style)]);
registerExtensionTextMarkerHandler("bell",     (key, style) => [fa("fa-bell", key, style)]);
registerExtensionTextMarkerHandler("plane",    (key, style) => [fa("fa-paper-plane", key, style)]);
registerExtensionTextMarkerHandler("terminal", (key, style) => [fa("fa-rectangle-terminal", key, style)]);
registerExtensionTextMarkerHandler("add",      (key, style) => [fa("fa-add", key, style)]);
registerExtensionTextMarkerHandler("import",   (key, style) => [fa("fa-file-import", key, style)]);
registerExtensionTextMarkerHandler("file-arrow-down", (key, style) => [fa("fa-file-arrow-down", key, style)]);
registerExtensionTextMarkerHandler("tower-broadcast", (key, style) => [fa("fa-tower-broadcast", key, style)]);
registerExtensionTextMarkerHandler("play",    (key, style) => [fa("fa-play", key, style)]);
registerExtensionTextMarkerHandler("stop",    (key, style) => [fa("fa-stop", key, style)]);

registerExtensionTextMarkerHandler("yellow-light", (key) => [<img key={key} src={yellowLight} style={{ width: "1em", height: "auto" }} />]);
registerExtensionTextMarkerHandler("amber-light",  (key) => [<img key={key} src={amberLight}  style={{ width: "1em", height: "auto" }} />]);
registerExtensionTextMarkerHandler("red-light",    (key) => [<img key={key} src={redLight}    style={{ width: "1em", height: "auto" }} />]);
registerExtensionTextMarkerHandler("green-light",  (key) => [<img key={key} src={greenLight}  style={{ width: "1em", height: "auto" }} />]);


import "../../schemaengine/client/SchemaLibsJson";
registerExtensionLibFunction("stripComments", (str: string) => stripJsonComments(str));




async function showMessage(type: "success" | "error" | "confirm", message: string) {

    const messageTxt = (message || "").substring(0, 1).toUpperCase() + (message || "").substring(1);

    // this.log("Toast, type=" + type + ", message=" + message);
    if (type === "success") {
        toast.success(messageTxt);
        return true;
    } else if (type === "error") {
        toast.error(messageTxt);
        return false;
    } else {
        return await dialog({
            title: "Confirm",
            description: messageTxt,
            actionLabel: strings.OK,
            cancelLabel: strings.CANCEL,
            faIcon: faInfo,
        });
    }
}


interface Self {
    modifyQueryExecuted: boolean;
    navigate: NavigateFunction;
    context: IDassUiAppContext;

    schemaRef: React.RefObject<SchemaControllerRef>;
    linkRef: React.RefObject<HTMLAnchorElement>
    props: ISchemaModalProps;

    refresh: () => void;

    debugLogBuf: any[];
    log: (...args) => void;


    // states
    debug: boolean;
    close: boolean;
    showModal: boolean;
    activeTab: string;

    libExtensions: {
        [name: string]: (...any) => any;
    }
    dict: ISchemaLocaleDictionary;

}




export function SchemaModal(props: ISchemaModalProps) {

    const selfRef = useRef<Self>();
    const [_updateCnt, setUpdateCnt] = useState<number>(0);
    const navigate = useNavigate();
    const context = useContext(AppContext);

    useEffect(() => {

        selfRef.current = {
            modifyQueryExecuted: false,
            navigate,
            context,
            linkRef: React.createRef<HTMLAnchorElement>(),
            schemaRef:  React.createRef<SchemaControllerRef>(),
            log: (...args) => bufLog(selfRef.current, ...args),
            debugLogBuf: [],
            refresh: () => setUpdateCnt(lst => lst + 1),
            props,

            // states
            debug: false,
            close: false,
            showModal: true,
            activeTab: "",
            
            libExtensions: {
                navigate,
                getLanguage: () => context?.navBarState?.language,
            },
            dict: {
                "false": strings.NO,
                "true": strings.YES,
                click_to_unlock: strings.CLICK_TO_UNLOCK,
                cancel: strings.CANCEL,
            }
        };

        setTranslationHandler(async (translations, verifications) => {
            try {
                selfRef.current.refresh();
                await GenericDassQuery("/rest/audit-translations", {
                    method: "POST",
                    data: { translations, verifications }
                });
            } catch (e) {
                console.log("Can't save translation audits", e.message);
            }
        });

        if (props.hooks) {
            props.hooks.updateValues = (obj: any) => {
                selfRef.current.schemaRef.current?.updateValues({ values: obj}, null, null);
            }
        }

        selfRef.current.refresh();

        return () => {
            // nothing to do a the moment
        };

    }, [])
    
    useEffect(() => {

        selfRef.current.props = props;
        selfRef.current.refresh();

    }, [props])

    return render(selfRef.current)
}




function isLocalHost() {
    return ["localhost", "127.0.0.1"].includes(window.location.hostname);
}
function hasDebugArg() {
    return window.location.search?.indexOf("debuG") > 0;
}



function bufLog(self: Self, ...args) {
    if (self.debug) {
        console.log(...args);
    } else {
        if (self.debugLogBuf && self.debugLogBuf.length < 100) {
            self.debugLogBuf.push(args);
        }
    }
}



function updateObjStates(self: Self, state: IUiSchemaUpdateState) {
        
    if (state.close && !self.close) {
        self.showModal = false;
        self.refresh();

        if (self.props.OnClose) {
            self.props.OnClose(state.success ? self.schemaRef?.current?.self.objects.newValues : null, state.success || self.modifyQueryExecuted);
        }
    }

    if (state.close != null) {
        self.close = state.close;
        self.refresh();
    }


    if (state?.debug && self.debugLogBuf) {
        // When debugging is enabled first time, we dump the buffer of the first log messages that was recorded up till now
        for (const db of self.debugLogBuf) {
            console.log(...db);
        }
        self.debugLogBuf = null;
    }
    if (state?.debug != null) {
        self.debug = state.debug;
        self.refresh();
    }

    if (state.currentValues && self.props.OnChange) {
        self.props.OnChange(state.currentValues, state.lastValues)
    }

    if (state.activeTab != null) {
        self.activeTab = state.activeTab;
        self.refresh();
    }

    if (state.update) {
        self.refresh();
    }
};




function render(self: Self) {

    if (!self) { return null; }


    const body = (
        <SchemaController
            ref={self.schemaRef}
            jsonSchema={self.props.Schema}
            jsonSchemaUrl={self.props.SchemaUrl}
            object={self.props.EditObject}
            initialReadOnly={self.props.EditObject ? self.props.EditObject.__readonly !== false : false}
            initialActiveTab={self.activeTab}
            updateState={(state) => updateObjStates(self, state)}
            getResources={(requestMethod: string, url: string, options: IGetResourcesOptions) => getResources(self, requestMethod, url, options) }
            showMessage={showMessage}
            loadDataOnOpen={self.props.loadDataOnOpen || false}
            localeDictionary={self.dict}
            libExtensions={self.libExtensions}
            debug={self.debug}
            log={self.log}
            getSettings={(scope: string) => getSettings(self, scope)}
            helpLinkCallback={(link) => helpLinkCallback(self, link)}
            defaultLayoutOptions={{
                titleLayout: "left",
                descriptionLayout: "popup",
                componentLayout: "right",
                titleWidthPercent: 40,
                numColumns: 0,
                cardStyle: "normal",
                panelLayout: "masonry",
                cardSize: "col-2",
                arrayElementRenderMode: "render-always",
                cardRenderMode: "render-after-visible",
                panelRenderMode: "render-after-visible",
            }}
        />
    );

    // This is not really clean. But the problem is that when calling this function, the state of the SchemaPanel
    // has not yet been updated, so it can't genereate the buttons based on its own inner state.
    const controlButtons = self.schemaRef?.current
        ? self.schemaRef?.current.getControlButtons(false,      // FIXME: why always false
                                                    isLocalHost() || hasDebugArg(), self.debug)
        : [];

    if (self.props.type === "modal") {

        return renderModal(self, body, controlButtons, self.showModal, () => {

            self.showModal = false;
            self.refresh();

            if (self.props.OnClose) {
                self.props.OnClose(null, self.modifyQueryExecuted);
            }
        });

    } else if (self.props.type === "navbar") {

        return body;

    } else {

        return self.props.breadCrumbArr 
                ? renderFullPage(self, self.props.type === "page-fluid", body, controlButtons) 
                : renderPage(self, self.props.type === "page-fluid", body, controlButtons);

    }
}






function renderModal(self: Self, body: JSX.Element, controlButtons: ReactNode, showModal: boolean, closeModal: () => void) {

    const uiSchema = self.schemaRef?.current?.self?.objects?.rootJsonSchema?.$uiSchema;

    return (
        <div>
            <a href={"#"} ref={self.linkRef} target="_blank" rel="noopener noreferrer" style={{display: "none"}}/>
            <Modal
                show={showModal}
                onHide={closeModal}
                className="schema-engine-content"
                size="xl"
                fullscreen={(windowWidth() < ipadWidth) ? true : "xxl-down"}
                backdrop="static"
            >
                <Modal.Header closeButton={true} className="schema-engine-header">
                    <Modal.Title>
                        {uiSchema && uiSchema.modal && uiSchema.modal.title || ""}
                    </Modal.Title>
                </Modal.Header>


                <Modal.Body className="schema-engine-body">
                    {body}
                </Modal.Body>


                <Modal.Footer className=" schema-engine-footer">
                    <div className="buttons">
                        {controlButtons}
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}



function renderPage(self: Self, fluid: boolean, body: JSX.Element, controlButtons: ReactNode) {

    return (
        <Container {...{fluid}} className="schema-detail-page" style={{width: '100%'}}>
            <a href={"#"} ref={self.linkRef} target="_blank" rel="noopener noreferrer" style={{display: "none"}}/>

            {controlButtons && <div className="header">
                <div className="buttons">
                    {controlButtons}
                </div>
            </div>}

            <div className="body" id="schema-detail-page-body">
                {body}
            </div>
  {/*        <ScrollButton />   */}
        </Container>
    );
}


function renderFullPage(self: Self, fluid: boolean, body: JSX.Element, controlButtons: ReactNode) {

    return (
        <div className={"child-tab-wrapper ow-dashboard"} >
            <div className="row mb-2 mr-0-only d-lg-flex single-page-header justify-content-between border-2 besides-data-table" >
                <div className="col-lg-4 col-md-auto col-xs-12">
                    {self.props.breadCrumbArr && <BreadcrumbComponent breadCrumbArr={self.props.breadCrumbArr}  />}
                </div>

                <div className="col-lg-80 col-md-auto col-xs-12 d-flex align-items-start flex-wrap">
                    {controlButtons}
                </div>
            </div> 

            { renderPage(self, fluid, body, null) }
        </div>
    );
}




   

/**
 * helpLinkCallback is invoked when the user press the context help link. As we need to be authenticated when accessing the documentation
 * in the DASS we first get the token from the DASS, then append it as an query to the help link, then finally "click" the link.
 * 
 * @param self 
 * @param link 
 * @returns 
 */
function helpLinkCallback(self: Self, link: string) {

    getResources(self, "get", "/rest/users?get_doctoken=true", { responseObject: {} as any, addAbortHandler: null, removeAbortHandler: null }).then((tok) => {

        const [root,hash] = link.split("#");
        const [path,query] = root.split("?");
        const q = (query || "") + (tok.data.token ? (query ? "&t=" : "t=") + tok.data.token : "");

        if (tok.ok) {
            self.linkRef.current.href = path + (q ? "?" + q : "") + (hash ? "#" + hash : "");
            self.linkRef.current.click();
        }
    });
    return false;
}


function getSettings(self: Self, scope: string) {
    if (scope === "default-map-center") {
        const ui = self.context.user?.ui_settings || {};
        return {
            lat: ui.map_center_latitude, lng: ui.map_center_longitude, zoom: ui.map_zoom
        };

    } else if (scope === "map-provider") {
        const env = self.context.user?._environment;
        if (env && env.map_provider_tileurl) {
            return { 
                url: env.map_provider_tileurl,
                attribution: env.map_provider_attribution,
                subDomains: env.map_provider_sub_domains,
                maxNativeZoom: env.map_provider_max_native_zoom,
            };
        }
        return null;
    }

    return {};
}




async function getResources(self: Self, requestMethod: string, url: string, options: IGetResourcesOptions) {
    let abort = false;

    const returnData: IRequestReturnData = {} as any;
    const abortHandle = () => {
        if (abort) { return; }
        abort = true;
        if (returnData?.abort) { returnData.abort(); }
    };


    try {
        if (options.addAbortHandler) {
            options.addAbortHandler(abortHandle);
        }

        const method: IRequestMethod = (requestMethod || "get").toUpperCase() as any;
        if (method !== "GET") {
            self.modifyQueryExecuted = true;
        }

        self.log(`Loading resource ${method} '${url}'`);


        let prefix: string = null;
        if (url.startsWith(":test:")) {
            url = url.substring(6);
            if (testPrefix && ["localhost", "127.0.0.1"].includes(window.location.hostname)) {
                prefix = testPrefix;
            }
        }

        // When we call the fetch() we receive back the response object so we can extract the headers.
        // This function will take the raw headers and turns them into an object.
        //
        const unpackReturnData = () => {
            if (options.responseObject && !options.responseObject.headers && returnData.response) {
                options.responseObject.headers = {};
                returnData.response.headers.forEach((value, key) => {
                    options.responseObject.headers[key] = value;
                });
            }
        }


        const chunkCallback = async (data: string, chunkIdx: number) => {
            if (abort) { return; }
            unpackReturnData();
            if (options?.chunkCallback) {
                options.responseObject.chunkIdx = chunkIdx;
                await options.chunkCallback(data, chunkIdx);
            }
        }

        // FIXME: why is the context not set sometimes?
        const data = url === "/rest/users/$" && method === "GET" && self.context?.user?.userid
            ? { data: self.context.user, status: 200 }
            : await GenericDassQuery(url, {
                data: options && options.body,
                headers: options && options.headers,
                method, prefix, chunkCallback, returnData,
            });

        if (abort) {
            throw new Error("ABORT");
        }

        unpackReturnData();
        delete options.responseObject.chunkIdx;

        // If we update the user and set the ui_settings, we need to reflect the update immediately
        if (url === "/rest/users/$" && method === "PUT"  &&  options?.body?.ui_settings) {
            self.context.updateUser({ ...self.context.user, ui_settings: options?.body?.ui_settings });
        }
        if (options.removeAbortHandler) {
            options.removeAbortHandler(abortHandle);
        }

        return { ok: data.status >= 200 && data.status <= 206, data: data.data, status: data.status };
    } catch (e) {

        if (options.removeAbortHandler) {
            options.removeAbortHandler(abortHandle);
        }

        if (abort) {
            return { ok: false, data: null, status: -1 };
        } else {
            console.log("Error loading resource '" + url + "'", e);
        }
        return { ok: false, data: e.message, status: e.status };
    }
};






let privateSetModal: (obj) => void;

export const SchemaModalContainer = () => {

    const [modal, setModal] = useState(null);

    useEffect(() => {
        privateSetModal = setModal;
        return () => privateSetModal = null;
    }, [])
    
    return modal;
}



export const ShowSchemaAsModal = async (schemaUrl: string, returnModelValue: boolean = false) => {

    return new Promise<boolean | object>((resolve, reject) => {
          
        privateSetModal?.(<SchemaModal
            key="modal"
            SchemaUrl={schemaUrl}
            OnClose={(action) => { 
                privateSetModal?.(null);
                resolve(returnModelValue ? action : (action != null));          
            }}
            type="modal"
        />);
    });
}

