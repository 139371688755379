import { strings } from "./../../services/Localization";
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';
import { faAdd } from '@fortawesome/free-solid-svg-icons'
import { getAppBase, getLabelBasedOnServerType } from '../../utils/consts';
import { TAB_ADD_RESOURCE } from '../../datatypes/tabsconstants'



const detailPageNav = (detailPageNavArgs) => {

    const { navigate, tab, id, row } = detailPageNavArgs;
    let prevPageUrl = detailPageNavArgs.hasOwnProperty('detailPageNavArgs') ? detailPageNavArgs['prevPageUrl'] : "-1";
    if (id) {
        navigate(`${getAppBase()}/resource/${id}/${tab}`, { state: { row: row, prevPageUrl: prevPageUrl } })
    } else {
        navigate(`${getAppBase()}/resource/${tab}`, { state: { tab: tab, prevPageUrl: prevPageUrl } })
    }
}

const ResourceProps: DetailPagePropsType = {
    getBreadCrumbArr: (breadCrumbArgs) => {

        const breadCrumb:BreadCrumbType[] = [
            getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, '', "/app/resource"),
            { label: strings.NAV_RESOURCE_ITEM, url: "/app/resource" },
            { label: strings.ADD_RESOURCE, url: '' }
        ]
        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {

        // FIXME Here we need to define prevPageUrl from action args
        let prevPageUrl = "/app/resource"
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.ADD_RESOURCE,
                icon: faAdd,
                key: TAB_ADD_RESOURCE,
                action: (id) => detailPageNav({ navigate: navigate, tab: TAB_ADD_RESOURCE, id: null, row: null, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url:  `/rest/resources_storage?get_schema=post,dassui`,
                    backUrl: prevPageUrl
                },
                EditObject: { __readonly: false }
            },
        ]
        return actions;
    }
}


export { ResourceProps };
