import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';
import { strings } from "../../services/Localization";
import { faList } from '@fortawesome/pro-regular-svg-icons'
import { getAppBase, getLabelBasedOnServerType } from '../../utils/consts';
import {  TAB_ADD_ALARM_DEFINITION, TAB_ALARM_DEFINITION_INFO  } from '../../datatypes/tabsconstants'



const addPageNav = ( navigate, tab) => {
    
    navigate( `${getAppBase()}/alarmdefinition/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/alarmdefinition`}})
}


const detailPageNav = ( actionArgs ) => {

    const { navigate, tab, id, row } = actionArgs;
    let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : null;
    navigate(`${getAppBase()}/alarmdefinition/${id}/${tab}`, {state: { row: row, prevPageUrl:prevPageUrl}})
}


export const AlarmDefinitionAddProps:DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';

        const breadCrumb:BreadCrumbType[] = [
            getLabelBasedOnServerType(strings.NAV_ALERTS, '',  prevPageUrl),
            {label: strings.NAV_MANAGE_ALARM_DEFINITIONS,  url: prevPageUrl},
            {label: strings.ADD_ALARM_DEFINITION,  url:''}
        ]
        return breadCrumb;
    },    
    getActions : (actionArgs, navigate) => {
        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';

        const actions: DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.NAV_MANAGE_ALARM_DEFINITIONS,
                icon: faList,
                key: TAB_ADD_ALARM_DEFINITION,
                action: (id) => addPageNav(navigate, TAB_ADD_ALARM_DEFINITION),
                content: {
                    type: 'SchemaModal',
                    url:'/rest/alarm-definitions?get_schema=dassui,post',
                    backUrl: prevPageUrl
                }
            }                 
            
        ]

        return actions;
    }
}

export const AlarmDefinitionEditProps:DetailPagePropsType =  {

    getBreadCrumbArr: ( breadCrumbArgs ) => {
        
        let id = breadCrumbArgs.hasOwnProperty('id') ? breadCrumbArgs['id'] : 0;
        let alarmDefinitionName = breadCrumbArgs?.row?.name || "";
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '';
        return [
            getLabelBasedOnServerType(strings.NAV_ALERTS, '',  prevPageUrl),
            {label: strings.NAV_MANAGE_ALARM_DEFINITIONS, url: prevPageUrl}, 
            { label: alarmDefinitionName, url:'', comment: id}
        ]
    },

    getActions : (actionArgs, navigate) => {

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        const { row } = actionArgs;
        const alarmDefinitionUuid = row.hasOwnProperty('alarmDefinitionUuid') ? row.alarmDefinitionUuid : (row.hasOwnProperty('alarmDefinitionUuid') ? row.alarmDefinitionUuid : null);
                
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.EDIT_ALARM_DEFINITION,
                icon: faList,
                key: TAB_ALARM_DEFINITION_INFO,
                action: (row) => detailPageNav({ navigate: navigate, tab:TAB_ALARM_DEFINITION_INFO, id:alarmDefinitionUuid, row:row, prevPageUrl:prevPageUrl}),
                content: {
                    type: 'SchemaModal',
                    url:'/rest/alarm-definitions?get_schema=dassui,put',
                    backUrl: prevPageUrl
                },
                EditObject: alarmDefinitionUuid ? { alarmDefinitionUuid } : null
            }
        ]

        return actions;
    }
}





