import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { AttributionControl, MapContainer, Marker, Polyline, Popup, TileLayer, useMap, useMapEvent } from "react-leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';

import "leaflet-imageoverlay-rotated";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css"
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import { IUiSchemaElemArgs } from "./SchemaController";
import { registerComponentHandler, registerJsonSchema } from "./SchemaExtensions";



// Imports for map extensions
import L from 'leaflet'
import ReactDOMServer from "react-dom/server";
import { readValue, vegaLikeTransforms } from "./VegaLikeTools";
import { mapJsonSchema, MapSchema, MapSchemaImageLayer, MapSchemaPathLayer, MapSchemaPointLayer } from "./ExtMapSchemaTypes";




registerJsonSchema("internal:map", ["*"], mapJsonSchema);

const defaultAnchorIcon = L.divIcon({
    iconSize: [8, 12],
    iconAnchor: [5, 15],
    popupAnchor: [1, -15],
    className: "default_anchor_icon",
    html: ReactDOMServer.renderToStaticMarkup(<i className="fa-solid fa-location-dot" style={{color: "black", opacity: 0.5}} />)
});



const SearchMarkerIcon = L.divIcon({
    iconSize: [16, 24],
    iconAnchor: [8, 24],
    popupAnchor: [1, -30],
    className: "default_icon",
    html: ReactDOMServer.renderToStaticMarkup(<i className="fa-regular fa-magnifying-glass-location fa-2x" style={{color: "red"}} />)
});

const defaultColors = [
	"#1f77b4", // - Blue
	"#ff7f0e", // - Orange
	"#2ca02c", // - Green
	"#d62728", // - Red
	"#9467bd", // - Purple
	"#8c564b", // - Brown
	"#e377c2", // - Pink
	"#7f7f7f", // - Gray
	"#bcbd22", // - Olive
	"#17becf", // - Teal
];


function makeMarker(map: {}, type: string, color: string) {

	const key = type + "_" + color;
	if (map[key]) { return map[key]; }

	try {
		const markerIcon = L.divIcon({
			iconSize: [16, 24],
			iconAnchor: [8, 24],
			popupAnchor: [1, -30],
			className: "default_icon" + color + "_key",
			html: ReactDOMServer.renderToStaticMarkup(<i className="fa-solid fa-location-dot fa-2x" style={{color}} />)
		});
		map[key] = markerIcon;
		return markerIcon;

	} catch (e) {
		console.error(e.message);
	}

	return null;
}






interface MapSchemaPath {
	txt: string, 
	points: [number, number][];
	color: string;
}

interface MarkerPoint {
	lat: number;
	lng: number;
	icon: L.DivIcon;
	title: ReactNode;
}


interface MapSchemaInternals {
	mapProvider: { url: string, attribution: string, maxNativeZoom: number, subDomain: string[] }

	markerIcons: { [typeColor: string]: L.DivIcon };
	markers: MarkerPoint[];
	clusterMarkers: MarkerPoint[];
	paths: MapSchemaPath[];
	images: Array<{ url: string, bounds: [number, number][], opacity: number, key: string }>;

	center: { lat: number, lng: number, key: string }
	bounds: [number, number][];
	zoom: number;
	zoomKey: string;

	maxZoom: number;
	mouseScrollWheelZoom: boolean;

	lastValues: any;
	needRecenter: boolean;
	readOnly: boolean;

	calcBounds: [number, number][];

	map: L.Map;
}


const RotatedImageOverlay = ({ url, bounds, opacity }) => {
	const map = useMap();
  
	useEffect(() => {
		// Use the leaflet-rotate-image plugin to add rotated image

		let imageOverlay: L.ImageOverlay;

		if (!bounds || !Array.isArray(bounds) || bounds.length < 2) { return null; }

		if (bounds.length === 3) {
			imageOverlay = L.imageOverlay.rotated(url, bounds[0], bounds[1], bounds[2], { opacity });
			imageOverlay.addTo(map);
		} else if (bounds.length === 2) {
			imageOverlay = L.imageOverlay(url, bounds, { opacity });
			imageOverlay.addTo(map);
		} else {

			return null;

		}
  
		// Clean up on unmount
		return () => {
			map.removeLayer(imageOverlay);
		};
	}, [map, url, bounds, opacity]);
  
	return null;
};


const MarkerLayer = (props: { markers: MarkerPoint[] }) => {

	const map = useMap();


	useEffect(() => {

		const markers = L.markerClusterGroup();

		for (const mrk of props.markers) {

			const { lat, lng, icon, title } = mrk;
			const marker = L.marker(new L.LatLng(lat, lng), { icon });

			marker.bindPopup(ReactDOMServer.renderToString(title));
			markers.addLayer(marker);
		}
		map.addLayer(markers);

		return () => {
			map.removeLayer(markers);
		};

	}, props.markers);

	return null;
}







const MapSchemaExtension = (props: { args: IUiSchemaElemArgs, editMode: boolean }) => {

	const { args } = props;
	const { key } = args;
	let schema: MapSchema = args.value;

	const mapRef = useRef<L.Map>();
	const divRef = useRef<HTMLDivElement>();
	const memRef = useRef<MapSchemaInternals>({ center: { }, me: "hallo", markerIcons: { } } as any);
	const [updateCnt, setUpdateCnt] = useState<number>(0);
	const editMode = props.editMode;

	useEffect(() => {

		const mem = memRef.current;

		if (mem.center.lat == null) {
			const def = args.getSettings("default-map-center");
			mem.zoom       = def?.zoom || 13;
			mem.center.lat = def?.lat  || 0;
			mem.center.lng = def?.lng  || 0;
			mem.center.key = "";
		}

		const resizeObserver = new ResizeObserver(() => {
			mapRef.current?.invalidateSize({ debounceMoveend: true })
		});
		resizeObserver.observe(divRef.current);

		return () => {
			resizeObserver.disconnect();
		}

	}, []);


	useEffect(() => {
	
		try {

			const mem = memRef.current;

			mem.mapProvider = { 
				url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", maxNativeZoom: 19, 
				attribution: "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
				subDomain: ["a", "b", "c"] 
			};

			const mapProvider = args.getSettings("map-provider");
			if (mapProvider) {
				const { url, attribution, maxNativeZoom, subDomains} = mapProvider;
				if (url && typeof url === "string" && typeof attribution === "string" && typeof maxNativeZoom === "number" && typeof subDomains === "string") {
					mem.mapProvider  = { url, attribution, maxNativeZoom, subDomain: subDomains.split(",") };
				}
			}

			if (mem.readOnly !== props.args.readOnly) {
				mem.readOnly = props.args.readOnly;
				setUpdateCnt(p => p + 1);
			}

			if (mem.lastValues === schema) { return; }
			mem.lastValues = schema;

			/**
			 * Perform standard vega-lite transformations
			 */
			let values = vegaLikeTransforms(schema.data?.values, schema.transform);
			if (values != schema.data?.values) {
				schema = { ...schema, data: { ...schema.data, values }};
			}
		

			mem.clusterMarkers = [];
			mem.markers = [];
			mem.paths   = [];
			mem.images  = [];

			const clusterMarkers = mem.clusterMarkers;
			const markers = mem.markers;
			const paths   = mem.paths;

			let minLat = 360;
			let maxLat = -360;
			let minLng = 360;
			let maxLng = -360;

			for (const llayer of [schema, ...(schema.layer || [])]) {
				if (llayer?.mark == null) { continue; }

				const layerKey = llayer === schema ? "/" : "/layer/" + schema.layer.findIndex(l => l == llayer) + "/";

				const mark = typeof llayer.mark === "string" ? llayer.mark :
								typeof llayer.mark === "object" && typeof llayer.mark?.type === "string" ? llayer.mark?.type
									: null;

				if (mark === "path") {

					const layer = llayer as MapSchemaPathLayer;
					const pathsMap: { [name: string]: MapSchemaPath } = {};
					let colorIdx = 0;
					for (let idx = 0; idx < schema.data?.values?.length || 0; idx++) {

						let path: MapSchemaPath;
						if (layer.encoding.color) {
							const name = readValue(schema, layer.encoding.latitude,  "number", idx);
							path = pathsMap[name] = pathsMap[name] || { txt: "", points: [], color: defaultColors[colorIdx++ % 19] };
						} else {
							path = pathsMap["__"] = pathsMap["__"] || { txt: "", points: [], color: defaultColors[colorIdx++ % 19] }
						}

						const lat = readValue(schema, layer.encoding.latitude,  "number", idx) || 0;
						const lng = readValue(schema, layer.encoding.longitude, "number", idx) || 0;

						path.points.push([lat, lng]);
					}

					for (const name of Object.keys(pathsMap)) {
						const path = pathsMap[name];
						minLat = Math.min(minLat, ...path.points.map(p => p[0]));
						maxLat = Math.max(maxLat, ...path.points.map(p => p[0]));
						minLng = Math.min(minLng, ...path.points.map(p => p[1]));
						maxLng = Math.max(maxLng, ...path.points.map(p => p[1]));
						paths.push(path);
					}

				} else if (mark === "point") {

					const layer = llayer as MapSchemaPointLayer;
					let cluster = false;

					if (typeof layer.mark === "object") {
						cluster = readValue(schema, layer.mark.cluster, "boolean", 0) ?? false;
					}

					for (let idx = 0; idx < schema.data?.values?.length || 0; idx++) {
						const lat = readValue(schema, layer.encoding.latitude,  "number", idx) || 0;
						const lng = readValue(schema, layer.encoding.longitude, "number", idx) || 0;
						const color = readValue(schema, layer.encoding.color,   "string", idx) || "blue";
						const legend = readValue(schema, layer.encoding.legend, "string", idx);

						(cluster ? clusterMarkers : markers).push({ 
							lat, lng, 
							icon: makeMarker(mem.markerIcons, "", color),
							title: args.stringToComponent(legend, args, args?.key) 
						});

						minLat = Math.min(minLat, ...markers.map(p => p.lat));
						maxLat = Math.max(maxLat, ...markers.map(p => p.lat));
						minLng = Math.min(minLng, ...markers.map(p => p.lng));
						maxLng = Math.max(maxLng, ...markers.map(p => p.lng));
					}

				} else if (mark === "image") {

					const layer = llayer as MapSchemaImageLayer;
					const url = readValue(schema, layer.encoding.url, "string", 0);
					const opacity = readValue(schema, layer.encoding.opacity, "number", 0) ?? 1;
					const bounds = layer.encoding.bounds;

					mem.images.push({ url, bounds, opacity, key: props.editMode ? layerKey + "encoding/bounds/" : "" });
				}
			}


			mem.maxZoom = readValue(schema, schema.config?.maxZoom, "number") || 20;
			mem.mouseScrollWheelZoom = readValue(schema, schema.config?.mouseScrollWheelZoom, "boolean") ?? true;

			let boundsChanged = false;

			if (markers.length > 0 || paths.length > 0) {
				if (!mem.calcBounds ||
					mem.calcBounds[0][0] !== minLat || mem.calcBounds[0][1] !== minLng ||
					mem.calcBounds[1][0] !== maxLat || mem.calcBounds[1][1] !== maxLng) {
						mem.calcBounds = [[minLat, minLng], [maxLat, maxLng]];
						boundsChanged = true;
				}
			}


			if (schema?.config?.zoom != null) {
				const zoom = readValue(schema, schema.config.zoom, "number", 0) || 13;

				if (zoom !== mem.zoom) {
					mem.needRecenter = true;
				}

				mem.zoom    = zoom;
				mem.zoomKey = "/config/zoom"

			} else {

				mem.bounds     = [[minLat, minLng], [maxLat, maxLng]];
				if (boundsChanged) {
					mem.needRecenter = true;
				}
			}

			if (schema?.config?.center) {
				const lat = readValue(schema, schema.config.center[0],  "number", 0) || 0;
				const lng = readValue(schema, schema.config.center[1],  "number", 0) || 0;

				if (lat !== mem.center.lat || lng !== mem.center.lng) {
					mem.needRecenter = true;
				}		
				mem.center.lat = lat;
				mem.center.lng = lng;
				mem.center.key = "/config/center";

			} else {

				if (boundsChanged) {
					mem.center.lat = (minLat + maxLat) / 2;
					mem.center.lng = (minLng + maxLng) / 2;
					mem.center.key = "";
				}

			}

			setUpdateCnt(p => p + 1);

		} catch (e) {
			console.log(e.message);
			memRef.current.markers = [];
			memRef.current.paths   = [];
			memRef.current.zoom   = 13;
			memRef.current.center = { lat: 0, lng: 0, key: "" };
		}
	}, [props])




	const markerDragEnd = (e: any, key: string) => {

		const mem = memRef.current;
		const { lat, lng } = e.target._latlng;

		if (key && !mem.readOnly && editMode) {
			args.update({ value: {
				type: "key-update",
				subType: "drag-end",
				keys: {
					[`${key}/0`]: lat,
					[`${key}/1`]: lng,
				}
			}});
		}
	}


	const markerHandlers = {
		dragend: (e: any) => {
			let { lat, lng } = e.target._latlng;
			lng = lng % 360;
			lng = lng > 180 ? lng - 360 : lng < -180 ? lng + 360 : lng; 
			const k = 1000000;
			args.update({value: { lat: Math.round(lat * k) / k, lng: Math.round(lng * k) / k }});
		},
	};

	function EventHandlers(props: {}) {

		useMapEvent('moveend', (event) => {
			const mem = memRef.current;
			const map = event.target;
			const center = map.getCenter();
			const zoom = map.getZoom();
			const centerKey = mem.center.key;
			const zoomKey = mem.zoomKey;

			if (zoom === mem.zoom && mem.center.lat === center.lat && mem.center.lng === center.lng) {
				return;
			}

			mem.zoom       = zoom;
			mem.center.lat = center.lat;
			mem.center.lng = center.lng;

			if ((zoomKey || centerKey) && !mem.readOnly && editMode) {
				const keys = {};
				const obj = { value: { type: "key-update", subType: "map", keys }};

				if (centerKey) {
					keys[`${centerKey}/0`] = center.lat;
					keys[`${centerKey}/1`] = center.lng;
				}
				if (zoomKey) {
					keys[`${zoomKey}`] = zoom;
				}
				args.update(obj);	
			}
		});


		useMapEvent('zoomend', (event) => {
			const mem  = memRef.current;
			const map  = event.target;
			const zoom = map.getZoom();

			if (zoom === mem.zoom) { return; }
			mem.zoom = zoom;

			if (mem.zoomKey && !mem.readOnly && editMode) {
				args.update({ value: {
					type: "key-update",
					subType: "zoomend",
					keys: {
						[`${mem.zoomKey}`]: zoom,
					}
				}});	
			}
		});

		return null;
	}

	function ChangeView(props: { center: L.LatLngExpression, zoom: number }) {

		const mem = memRef.current;
		const map = useMap();
		mem.map = map;

		if (mem.needRecenter) {

			if (!mem.zoom && mem.bounds) {
				mem.zoom = map.getBoundsZoom(memRef.current.bounds);
			} 

			map.setView(mem.center, mem.zoom);
			mem.needRecenter = false;
		}

		return null;
	}


	const SearchControl = (props) => {
		const map = useMap();
		
		useEffect(() => {
			const searchControl = GeoSearchControl({
				provider: new OpenStreetMapProvider(),
				marker: { icon: SearchMarkerIcon },
				...props,
		  	});
	  
		  	map.addControl(searchControl);
		  	return () => map.removeControl(searchControl) as any;
		}, [props]);
	  
		return null;
	};

	return useMemo(() => {

			const mem = memRef.current;
			const readOnly = mem.readOnly;

			return (
				<div ref={divRef} className="gs-no-drag" style={{ width: "100%", height: "100%", position: "relative" }}>

					{mem.markers &&	<MapContainer 
							ref={mapRef}
							style={{ width: "100%", height: "100%", position: "relative" }}
							attributionControl={false} key={key} center={mem.center}
							maxZoom={mem.maxZoom} zoom={mem.zoom}  
							scrollWheelZoom={mem.mouseScrollWheelZoom}
					>
						<EventHandlers />
						<ChangeView center={mem.center} zoom={mem.zoom} />
						<AttributionControl prefix='<a href="https://leafletjs.com/">Leaflet</a>' />
						<TileLayer
							maxZoom={mem.maxZoom}
							maxNativeZoom={mem.mapProvider.maxNativeZoom}
							attribution={mem.mapProvider.attribution}
							subdomains={mem.mapProvider.subDomain}
							url={mem.mapProvider.url}
						/>

						{/* 
						* First we render the overlay images. In the case of editMode, we add also anchor points to allow us to 
						* drag the positions in place
						*/}
						{mem.images.map((image, idx) => (
							<RotatedImageOverlay key={"" + image.url + idx}  url={image.url} bounds={image.bounds} opacity={image.opacity}/>
						))}
						{mem.images.map((image, idx) => (
							image.bounds[0] && image.key && !readOnly ?
								<Marker 
									key={"" + image.url + idx + "topleft"}  position={[image.bounds[0][0], image.bounds[0][1]]} icon={defaultAnchorIcon} 
									draggable={true} eventHandlers={{ dragend: (e) => markerDragEnd(e, image.key + "0") }} 
								/>
								: null
						))}
						{mem.images.map((image, idx) => (
							image.bounds[1] && image.key && !readOnly ?
								<Marker 
									key={"" + image.url + idx + "topleft"}  position={[image.bounds[1][0], image.bounds[1][1]]} icon={defaultAnchorIcon} 
									draggable={true}  eventHandlers={{ dragend: (e) => markerDragEnd(e, image.key + "1") }} 
								/>
								: null
						))}
						{mem.images.map((image, idx) => (
							image.bounds[2] && image.key && !readOnly ?
								<Marker 
									key={"" + image.url + idx + "topleft"}  position={[image.bounds[2][0], image.bounds[2][1]]} icon={defaultAnchorIcon} 
									draggable={true}  eventHandlers={{ dragend: (e) => markerDragEnd(e, image.key + "2") }} 
								/>
								: null
						))}


						{/* 
						* Now add the normal markers
						*/}
						{mem.markers.map((marker, idx) => (
							<Marker key={"" + marker.lat + marker.lng + idx}  position={[marker.lat, marker.lng]} icon={marker.icon} draggable={false} eventHandlers={markerHandlers}>
								{marker.title && (<Popup>
									{marker.title}
								</Popup>)}
							</Marker>
						))}

						{/* 
						* Now add the markers that will cluster
						*/}
						{mem.clusterMarkers.length > 0 && <MarkerLayer markers={mem.clusterMarkers} />}

						{/* 
						* Now add the paths
						*/}
						{mem.paths.map((path, idx) => (
							<Polyline key={"" + path.txt + idx}  positions={path.points} color={path.color}>
								{path.txt && (<Popup>
									{path.txt}
								</Popup>)}
							</Polyline>
						))}

						<SearchControl />
					</MapContainer>}
				</div>
			);
		},
		[updateCnt]
	);
};



registerComponentHandler("mapschema", (args: IUiSchemaElemArgs) => <MapSchemaExtension key={args.fullkey} args={args} editMode={args?.uiElem?.componentOptions?.editMode}/>);
