import "babel-polyfill";
import React, { useContext, useEffect, useRef } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import ActiveConnections from "./pages/Applications/ActiveConnections";
import EventLog from "./pages/Applications/EventLog";
import Profiles from "./pages/Profiles/Profiles";
import Accounts from "./pages/Accounts/Accounts";
import NetworkMap from "./pages/Devices/NetworkMap";
import { SignIn } from "./pages/SignIn/SignInPage";
import { ResetPassword } from "./pages/ResetPassword/ResetPasswordPage";
import { Welcome } from "./pages/Welcome/WelcomePage";
import { SwaggerPage } from "./pages/Swagger/SwaggerPage";
import { RedocPage } from "./pages/Swagger/RedocPage";
import { LiveLog } from "./pages/LiveLog/LiveLog";
import { VerifyEmail } from "./pages/VerifyEmail/VerifyEmail";
import Groups from "./pages/Groups/Groups";
import Rules from "./pages/AlertNotification/Rules"
import Tags from "./pages/Tags/Tags";
import Resources from "./pages/Resources/Resources";
import { ResourceProps } from "./pages/Resources/ResourceAddProps"
import ResourceDetailProps from "./pages/Resources/ResourceDetailProps";
import MyDevices from "./pages/Devices/MyDevices";
import DmpDevices from "./pages/Devices/DmpDevices";
import Alarms from "./pages/Alarms/Alarms";
import { ServerAlarmRowProps, GatewayAlarmRowProps } from "./pages/Alarms/AlarmProps";
import ServersStatus from "./pages/ServerMonitoring/ServersStatus";
import { ServerStatusProps } from "./pages/ServerMonitoring/ServersStatusProps"
import GatewayTags from "./pages/GatewayTags/GatewayTags";
import { GatewayTagsProps, GatewayTagsRowProps, GatewayTagCategorysProps, GatewayTagCategorysDeleteProps } from "./pages/GatewayTags/GatewayTagsProps";
import GatewayAutitTrail from "./pages/GatewayAuditTrail/GatewayAuditTrail";
import GatewayJobs from "./pages/GatewayJobs/GatewayJobs";
import GatewayManageAuth from "./pages/GatewayManageAuthorization/GatewayManageAuth"
import { GatewayJobsRowProps } from "./pages/GatewayJobs/GatewayJobsProps";
import GatewayParameters from "./pages/GatewayParameters/GatewayParameters";
import Tenants from "./pages/Tenants/Tenants";
import { TenantProps, TenantRowProps } from "./pages/Tenants/TenantProps";

import GroupDetailProps from "./pages/Groups/GroupDetailProps";
import GroupAddProps from "./pages/Groups/GroupAddProps";
import GroupAppAssignmentProps from "./pages/Groups/GroupAppAssignmentProps";
import DeviceAppAssignmentProps from "./pages/Devices/DeviceBulkActionsProps"
import { MyAppDetailProps, MyAppAddDetailProps, MyAppEditGlobalPushProps} from "./pages/Applications/MyAppDetailProps";
import { DevicePageProps, DeviceRowProps} from "./pages/Devices/DeviceProps";
import { DevicePageProps as DmpDevicePageProps, DeviceRowProps as DmpDeviceRowProps} from "./pages/Devices/DmpDeviceProps";

import { GatewayRowProps, GatewayProps } from "./pages/Gateways/GatewayProps";
import { ProfileProps, ProfileRowProps } from "./pages/Profiles/ProfileProps";
import { RuleAddProps, RuleEditProps } from "./pages/AlertNotification/RuleProps";

import { AccountsUserProps, AccountsRowUserProps, AccountsRowCustProps, AccountsCustProps, AccountsRowOrgProps, 
         AccountsOrgProps, AccountsRowLoginProps, AccountsLoginProps } from "./pages/Accounts/AccountsProps";


import { SchemaModal, SchemaModalContainer } from "./components/SchemaModal/SchemaModal";

import DetailPage from "./components/Common/DetailPage";

import BatchRegistration from "./pages/Devices/BatchRegistration";


import MyApps from "./pages/Applications/MyApps";
import MyGateways from "./pages/Gateways/MyGateways";
import AdminDevices from "./pages/Find/AdminDevices";
import AdminDmpDevices from "./pages/Find/AdminDmpDevices";
import AdminUsers from "./pages/Find/AdminUsers";

import OmcGateways from "./pages/OmcGateways/OmcGateways";
import { OmcGatewayProps, OmcGatewayRowProps, OmcGatewayMapRowProps } from "./pages/OmcGateways/OmcGatewayProps";
import GatewayBulkActionProps from "./pages/OmcGateways/OmcGatewayBulkActionProps";

import OmcDevices from "./pages/OmcDevices/OmcDevices";
import { OmcDeviceProps, OmcDeviceRowProps } from "./pages/OmcDevices/OmcDevicesProps";



import { NavBar } from "./components/NavBar/NavBar";
import { NstNavBar } from "./components/NavBar/NstNavBar";
import AppContext, { IDassUiAppContext } from "./context/AppContext";


import Timer from './components/Timer';
import BlockedGateways from "./pages/BlockedGateways/BlockedGateways";
import { BlockedGatewaysRowProps } from "./pages/BlockedGateways/BlockedGatewaysProps"
import { GatewayManageAuthProps } from "./pages/GatewayManageAuthorization/GatewayManageAuthProps"

import { JoinServers, GlobalJoinServers } from "./pages/JoinServers/JoinServers";
import { JoinServersRowProps, JoinServersProps, GlobalJoinServersRowProps, GlobalJoinServersProps } from  "./pages/JoinServers/JoinServerProps";


import { GatewayParametersProps, GatewayParametersRowProps } from "./pages/GatewayParameters/GatewayParametersProps";
import { ToastContainer } from "react-toastify";
import NstContainer from './components/NavBar/NstContainer';
import MyCampaigns from "./pages/Campaigns/MyCampaigns";
import { TagRowProps , TagProps } from "./pages/Tags/TagAddProps"

//import CreateCampaign from "./pages/Campaigns/CreateCampaign";


import CampaignStepper from "./pages/Campaigns/MyCampaignStepper";
import MyCampaignsSessions from "./pages/Campaigns/MyCampaignSessions";
import SessionDevices from "./pages/Campaigns/SessionDevices";
import { strings } from "./services/Localization";
import { getAppBase, getLabelBasedOnServerType } from "./utils/consts";
import { IConstants } from "./types";
import { isMobile } from "react-device-detect";
import NamedServices from "./pages/NamedServices/NamedServices";
import { NamedServicesProps, NamedServicesRowProps } from "./pages/NamedServices/NamedServicesAddProps";
import { GenericDassQuery } from "./services/BasicDassQueries";
import DeviceImport from "./pages/Devices/DeviceImport";
import DeviceJobs from "./pages/DeviceJobs/DeviceJobs";
import NotificationPolicy from "./pages/AlertNotification/NotificationPolicy";
import DMPAlarms from "./pages/AlertNotification/Alarms";
import { NotificationPolicyAddProps, NotificationPolicyEditProps } from "./pages/AlertNotification/NotificationPolicyProps";
import AlarmDefinition from "./pages/AlertNotification/AlarmDefinition";
import { AlarmDefinitionAddProps, AlarmDefinitionEditProps } from "./pages/AlertNotification/AlarmDefinitionProps";
import DebugViewPage from "./pages/DebugViewPage";

import OmcRelays from "./pages/OmcRelays/OmcRelays";
import { OmcRelayProps, OmcRelayRowProps } from "./pages/OmcRelays/OmcRelaysProps";


declare const constants: IConstants;





const App = () => {
    const appContextObj = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();
    const isNst = (String(location.pathname).indexOf('/nst') >= 0);
    const user = appContextObj?.user || null; 
    const mar_imp = (appContextObj.isSignedIn(user) && appContextObj.isForwardedSignIn() && appContextObj.navBarState.forwardedSignIn )?  isMobile ? 'mar-120-mobile' : 'mar-120' : ''
    const effectCalled = useRef(false);     // In preparations for React 18

    let navWrapperClass = (location.pathname === '/signin') ? 'besides-data-table' : `${mar_imp} nav-wrapper besides-data-table` ;
    let wrapperClass = "wrapper";

    if (location.pathname === '/' || location.pathname === '/app' || location.pathname === '/nst') {

        wrapperClass = 'welcome';

    } else if (location.pathname === '/signin' || location.pathname === '/nst/network_map' || location.pathname === '/app/network_map') {

        wrapperClass = "";

    }

    useEffect(() => {
        async function checkWhoami() {

            try {
                // Handle remote login
                const searchObj: any = {};
                const hashObj: any = {};
                if (window.location.search) {
                    for (const arg of window.location.search.slice(1).split("&")) {
                        const i = arg.indexOf("=");
                        if (i > 0) {
                            searchObj[arg.slice(0, i)] = decodeURIComponent(arg.slice(i + 1));
                        }
                    }
                }
                if (window.location.hash) {
                    for (const arg of window.location.hash.slice(1).split("&")) {
                        const i = arg.indexOf("=");
                        if (i > 0) {
                            hashObj[arg.slice(0, i)] = decodeURIComponent(arg.slice(i + 1));
                        }
                    }
                }

                if (searchObj.code || hashObj.code) {

                    const tz = new Date().getTimezoneOffset();
                    const tf = new Date().toLocaleTimeString().match(/am|pm/i) ? "ampm" : "24h";
                    const { code, state } = searchObj.code ? searchObj : hashObj;
    
                    await GenericDassQuery("/signin?tz=" + tz + "&tf=" + tf, {
                        data: { code, state },
                        prefix: "", method: "PUT",
                    });
                    
                    // Remove the arguments from the URL to avoid seeing the code
                    history.replaceState(null, null as any, searchObj.code ? window.location.pathname : " ");

                    const user = await appContextObj.loadUser();
                    
                    // After login we reload the whole page
                    navigate(user.ui_settings?.landing_page === "nst"
                        && Object.keys(user).filter(k => k.startsWith("omc_")).length > 0
                            ? "/nst/network_map"
                            : (constants.landing_page_after_signin || `/`)
                    );

                    return;
                }

            } catch (error) {
                console.log("ERROR", error);        // This seems not quite correct.
                appContextObj.signOut();
            }

            // Finally we load the user
            appContextObj.loadUser();
        }

        // React 18 will mount, unmount then mount again. Therefore this will be called twice. To avoid that we
        // need to check if it has already been called.
        if (!effectCalled.current) {
            effectCalled.current = true;
            checkWhoami();
            appContextObj.CheckSystemError();
        }
    }, []);


    // Monitor the status of login. If we get logged out, we navigate back to main page.
    useEffect(() => {
        if (appContextObj.user && !appContextObj.user.userid) {
            console.log("Not signed in");
            if (!["/", "/app/signin", "/app/reset_password", "/app/verify_email"].includes(location.pathname)) {
                console.log("On page that required signin - move to /", location.pathname);
                navigate("/");
            }
        }
    }, [appContextObj.user])

   

    let rootClassName = isNst ? "ow-nst-wrapper" : "ob-wrapper";
    
    return (
        <div className={rootClassName} 
            onDrop={(event) => { event.preventDefault(); return false; }}
            onDragOver={(event) => { event.preventDefault(); return false; }}
        >

            <SchemaModalContainer />
            <ToastContainer stacked />
            <div className={`${navWrapperClass}`} >
                {isNst ? <NstNavBar /> : <NavBar />}
                <Timer />
            </div>
            <div className={`${wrapperClass}`} id={`id-${wrapperClass}`}>

                {appRoutes(appContextObj, navigate, appContextObj.user ? location : "/")}

            </div>
        </div>
    );
}





function appRoutes(appContextObj: IDassUiAppContext, navigate: NavigateFunction, location: any) {
    return (
    <Routes location={location}>
        <Route path="/" element={<Welcome />}></Route>
        <Route path="/app" element={<Welcome />}></Route>
        <Route path="/app/signin" element={<SignIn />}></Route>
        <Route path="/app/reset_password" element={<ResetPassword />}></Route>
        <Route path="/app/verify_email" element={<VerifyEmail />}></Route>
        <Route path="/app/network_map" element={<NetworkMap />}></Route>


        <Route path="/app/profiles/device" element={<Profiles key="device" profileType="device" />}>
            <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps}  profileType="device" />} />
            <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps}  profileType="device" />} />
        </Route>

        <Route path="/app/profiles/service" element={<Profiles key="service" profileType="service" />}>
            <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="service" />} />
            <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="service" />} />
        </Route>
        <Route path="/app/profiles/connectivity" element={<Profiles key="connectivity" profileType="connectivity" />}>
            <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="connectivity" />} />
            <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="connectivity" />} />
        </Route>

        <Route path="/app/profiles/connection" element={<Profiles key="connectivity" profileType="connection" />}>
            <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="connection" />} />
            <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="connection" />} />
        </Route>

        <Route path="/app/connections" element={<Profiles key="connectionInst" profileType="connectionInstance" />}>
            <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="connectionInstance" />} />
            <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="connectionInstance" />} />
        </Route>



        <Route path="/app/profiles/roaming" element={<Profiles key="roaming" profileType="roaming" />}>
            <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="roaming" />} />
            <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="roaming" />} />
        </Route>  
        <Route path="/app/profiles/qos" element={<Profiles key="qos" profileType="qos" />}>
            <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="qos"/>} />
            <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="qos" />} />
        </Route>                                      
        <Route path="/app/profiles/channel" element={<Profiles key="channel" profileType="channel" />}>
            <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="channel" />} />
            <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="channel" />} />
        </Route>                                                          

        <Route path="/app/profiles/relay" element={<Profiles key="relay" profileType="relay" />}>
            <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="relay" />} />
            <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="relay" />} />
        </Route>       

        <Route path="/app/join-servers" element={<JoinServers />}>
            <Route path=":tabname" element={<DetailPage detailProps={JoinServersProps} />} />
            <Route path=":id/:tabname" element={<DetailPage detailProps={JoinServersRowProps} />} />
        </Route>

        <Route path="/app/sys-join-servers" element={<GlobalJoinServers />}>
            <Route path=":tabname" element={<DetailPage detailProps={GlobalJoinServersProps} />} />
            <Route path=":id/:tabname" element={<DetailPage detailProps={GlobalJoinServersRowProps} />} />
        </Route>


        <Route path="/app/active_connections-v2" element={<ActiveConnections />}></Route>
        <Route path="/app/event_log" element={<EventLog />}></Route>
        <Route path="/app/swagger" element={<SwaggerPage />}></Route>
        <Route path="/app/apidoc" element={<RedocPage />}></Route>
        <Route path="/app/debugview" element={<DebugViewPage />}></Route>

        <Route path="/app/livelog" element={<LiveLog />}></Route>

        <Route path="/app/accounts/logins" element={<Accounts accountType="logins" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowLoginProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={AccountsLoginProps} />} />
        </Route>

        <Route path="/app/accounts/users" element={<Accounts accountType="users" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowUserProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={AccountsUserProps} />} />
        </Route>

        <Route path="/app/accounts/customers" element={<Accounts accountType="customers" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowCustProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={AccountsCustProps} />} />
        </Route>


        <Route path="/app/accounts/organisations" element={<Accounts accountType="organisations" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowOrgProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={AccountsOrgProps} />} />
        </Route>

        <Route path="/app/groups" element={<Groups />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={GroupDetailProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={GroupAddProps} />} />
                <Route path="bulkaction/:tabname" element={<DetailPage detailProps={GroupAppAssignmentProps} />} />
        </Route>

        <Route path="/app/tags" element={<Tags />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={TagRowProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={TagProps} />} />
        </Route>

        <Route path="/app/named-services" element={<NamedServices />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={NamedServicesRowProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={NamedServicesProps} />} />
        </Route>

        <Route path="/app/rules" element={<Rules />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={RuleEditProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={RuleAddProps} />} />
                <Route path="bulkaction/:tabname" element={<DetailPage detailProps={GroupAppAssignmentProps} />} />
        </Route>

        // NotificationPolicy
        <Route path="/app/notificationpolicy" element={<NotificationPolicy />} />
        <Route path="app/notificationpolicy/:tabname" element={<DetailPage detailProps={NotificationPolicyAddProps} />} />
        <Route path="/app/notificationpolicy/:id/:tabname" element={<DetailPage detailProps={NotificationPolicyEditProps} />} />


        // Alarm AlarmDefinition
        <Route path="/app/alarmdefinition" element={<AlarmDefinition />} />
        <Route path="app/alarmdefinition/:tabname" element={<DetailPage detailProps={AlarmDefinitionAddProps} />} />
        <Route path="/app/alarmdefinition/:id/:tabname" element={<DetailPage detailProps={AlarmDefinitionEditProps} />} />

        <Route path="/app/alarms" element={<DMPAlarms />} />
        

        <Route path="/app/jobs" element={<DeviceJobs />}>
                
        </Route>


        <Route path="/app/resource" element={<Resources />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={ResourceDetailProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={ResourceProps} />} />
                <Route path="bulkaction/:tabname" element={<DetailPage detailProps={GroupAppAssignmentProps} />} />
        </Route>

        <Route path="/app/my-devices" element={<MyDevices />}>
            <Route path=":tabname" element={<DetailPage detailProps={DevicePageProps} />} />
                <Route path=":id/:tabname" element={<DetailPage detailProps={DeviceRowProps} />} />
                <Route path="bulkaction/:tabname" element={<DetailPage detailProps={DeviceAppAssignmentProps} />} />
        </Route>

        <Route path="/app/dmp-devices" element={<DmpDevices />}>
            <Route path=":tabname" element={<DetailPage detailProps={DmpDevicePageProps} />} />
                <Route path=":id/:tabname" element={<DetailPage detailProps={DmpDeviceRowProps} />} />
                <Route path="bulkaction/:tabname" element={<DetailPage detailProps={DeviceAppAssignmentProps} />} />
        </Route>

        <Route path="/app/device-import" element={<DeviceImport />}>
        </Route>

        <Route path="/app/my-apps" element={<MyApps />}>
            <Route path=":id/:tabname" element={<DetailPage  detailProps={MyAppDetailProps} />} />
            <Route path=":tabname" element={<DetailPage detailProps={MyAppAddDetailProps} />} />
            <Route path="edit/:tabname" element={<DetailPage detailProps={MyAppEditGlobalPushProps} />} />
        </Route>

        <Route path="/app/my-gateways" element={<MyGateways />}>
            <Route path=":id/:tabname" element={<DetailPage  detailProps={GatewayRowProps} />} />
            <Route path=":tabname" element={<DetailPage  detailProps={GatewayProps} />} />
        </Route>

        <Route path="/app/admin-devices" element={<AdminDevices />}></Route>

        <Route path="/app/admin-dmp-devices" element={<AdminDmpDevices />}></Route>
        
        <Route path="/app/admin-users" element={<AdminUsers />}></Route>

        <Route path="/app/active_connections" element={<ActiveConnections />}></Route>
        
        <Route path="/app/mydevice-register" element={<BatchRegistration />}></Route>

        <Route path="/app/product-license" element={
            <SchemaModal key="product-license" SchemaUrl="/rest/license?get_schema=dassui,put" type="page"
                         breadCrumbArr={[getLabelBasedOnServerType(strings.NAV_ADMINISTRATIVE, strings.NAV_SYSTEM, ''), {label: strings.PRODUCT_LICENSE_TITLE, url: ''}]}
                         EditObject={{}}
                         OnClose={() => { appContextObj.loadUser(); navigate(-1); }} />}
        />


        <Route path="/app/mydashboard" element={
            <SchemaModal key="dashboard" SchemaUrl="/rest/users?get_schema=dashboard" type="page-fluid"
                breadCrumbArr={[{label: "Dashboard", url: ''}]}
                OnClose={() => {}} />}
        />
        <Route path="/app/dashboard-card-editor/:cardId" element={
            <SchemaModal key="query-card-editor" SchemaUrl="/rest/dashboard/cards?get_schema=dassui,put" type="page-fluid"
                breadCrumbArr={[{label: "Dashboard", url: "/app/mydashboard"}, {label: "Card Editor", url: ""}]}
                EditObject={{}}
                OnClose={() => { appContextObj.loadUser(); navigate("/app/mydashboard"); }} />}
        />
        <Route path="/app/dashboard-card-editor" element={
            <SchemaModal key="query-card-editor" SchemaUrl="/rest/dashboard/cards?get_schema=dassui,post" type="page-fluid"
                breadCrumbArr={[{label: "Dashboard", url: "/app/mydashboard"}, {label: "Card Editor", url: ""}]} 
                EditObject={{}}
                OnClose={() => { appContextObj.loadUser(); navigate("/app/mydashboard"); }} />}
        />
        <Route path="/app/dashboard-settings" element={
            <SchemaModal key="query-settings-editor" SchemaUrl="/rest/dashboard/pages?get_schema=dassui,post" type="page"
                breadCrumbArr={[{label: "Dashboard", url: "/app/mydashboard"}, {label: "Settings", url: ""}]} EditObject={{}}
                OnClose={() => { appContextObj.loadUser(); navigate("/app/mydashboard"); }} />}
        />



        <Route path="/app/eula" element={
            <SchemaModal key="eula" SchemaUrl="/rest/users?get_schema=dassui,eula" type="page"
                         breadCrumbArr={[{label: "Software license", url: ''}]}
                         OnClose={() => navigate(-1)} />}
        />


        <Route path="/app/user-settings" element={
            <SchemaModal key="user-settings"
                breadCrumbArr={[{ label: strings.NAV_USER_SETTINGS, url: "/app/user-settings" }]}
                SchemaUrl={`/rest/${ appContextObj.accountType(appContextObj.user)}s?get_schema=dassui,put`}
                EditObject={{ userid: appContextObj.user?.loginid || appContextObj.user?.userid, _editself: true}}   
                OnClose={(res, update) => { 
                    if (update) { appContextObj.loadUser(); }
                    navigate(-1);
                }}
                type="page"
                loadDataOnOpen={true} />}
        />

        <Route path="/app/about" element={
            <SchemaModal key="about-system"
                breadCrumbArr={[{ label: strings.NAV_ABOUT_SYSTEM, url: "/app/about" }]}
                SchemaUrl={`/rest/about?get_schema=dassui,put`}
                EditObject={{__readonly: false}}
                OnClose={() => { 
                    navigate(-1);
                }}
                type="page"
                loadDataOnOpen={true} />}
        />

        <Route path="/app/server-alarms" element={<Alarms key="server" alarmtype="server"/>}>
            <Route path=":id/:tabname" element={<DetailPage detailProps={ServerAlarmRowProps} />} />
        </Route>

            <Route path="/app/gateway-alarms" element={<Alarms key="gateway" alarmtype="gateway" />}>
            <Route path=":id/:tabname" element={<DetailPage detailProps={GatewayAlarmRowProps} />} />
        </Route>

        <Route path="/app/servers-status" element={<ServersStatus />} />

        <Route path="/app/gateway-tags" element={<GatewayTags />} >
            <Route path=":tabname" element={<DetailPage key="add" detailProps={GatewayTagsProps} />} />
            <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={GatewayTagsRowProps} />} />
        </Route>
        

        <Route path="/app/gateway-parameters" element={<GatewayParameters />} >
            <Route path=":tabname" element={<DetailPage key="add" detailProps={GatewayParametersProps} />} />
            <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={GatewayParametersRowProps} />} />
        </Route>

        <Route path="/app/gateway-audit-trail" element={<GatewayAutitTrail />} />

        <Route path="/app/tenants" element={<Tenants />}>
            <Route path=":tabname" element={<DetailPage key="add" detailProps={TenantProps} />} />
            <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={TenantRowProps} />} />
        </Route>

        <Route path="/app/omc-gateways" element={<OmcGateways />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={OmcGatewayRowProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={OmcGatewayProps} />} />
        </Route>

        <Route path="/app/gateway-manage-auth" element={<GatewayManageAuth />} >
            <Route path=":tabname" element={<DetailPage detailProps={GatewayManageAuthProps} />} />
        </Route>

        <Route path="/app/omc-devices" element={<OmcDevices />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={OmcDeviceRowProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={OmcDeviceProps} />} />
        </Route>
            <Route path="/app/my-campaigns" element={<MyCampaigns></MyCampaigns>}></Route>
            <Route path="/app/my-campaigns/:campaignid" element={<MyCampaignsSessions />} />
            <Route path="/app/my-campaigns/:campaignid/:sessionId" element={<SessionDevices />} />
            <Route path="/app/my-campaigns/create" element={<CampaignStepper />}></Route>
        {NstAppFunc(appContextObj, navigate)}
    </Routes>
    );
}


export default App;

const NstAppFunc = (appContextObj: IDassUiAppContext, navigate: NavigateFunction) => {

    return (
        <Route path="/nst" element={<NstContainer />}>
            <Route path="verify_email" element={<VerifyEmail />}></Route>
            <Route path="network_map" element={<NetworkMap />}></Route>
            
            <Route path="profiles/qos" element={<Profiles key="qos" profileType="qos" />}>
                <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="qos"/>} />
                <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="qos" />} />
            </Route>                                      
            <Route path="profiles/channel" element={<Profiles key="channel" profileType="channel" />}>
                <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="channel" />} />
                <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="channel" />} />
            </Route>                                                          
            <Route path="profiles/relay" element={<Profiles key="relay" profileType="relay" />}>
                <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="relay" />} />
                <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="relay" />} />
            </Route>                                                          

            <Route path="active_connections-v2" element={<ActiveConnections />}></Route>
            <Route path="event_log" element={<EventLog />}></Route>
            <Route path="swagger" element={<SwaggerPage />}></Route>

            <Route path="accounts/users" element={<Accounts accountType="users" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowUserProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={AccountsUserProps} />} />
            </Route>

            <Route path="accounts/customers" element={<Accounts accountType="customers" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowCustProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={AccountsCustProps} />} />
            </Route>


            <Route path="accounts/organisations" element={<Accounts accountType="organisations" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowOrgProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={AccountsOrgProps} />} />
            </Route>

            <Route path="groups" element={<Groups />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={GroupDetailProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={GroupAddProps} />} />
                <Route path="app-assignment" element={<DetailPage detailProps={GroupAppAssignmentProps} />} />
            </Route>

            <Route path="my-devices" element={<MyDevices />}>
                <Route path=":tabname" element={<DetailPage detailProps={DevicePageProps} />} />
                <Route path=":id/:tabname" element={<DetailPage detailProps={DeviceRowProps} />} />
            </Route>


            <Route path="my-apps" element={<MyApps />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={MyAppDetailProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={MyAppAddDetailProps} />} />
            </Route>

            <Route path="my-gateways" element={<MyGateways />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={GatewayRowProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={GatewayProps} />} />
            </Route>

            <Route path="admin-devices" element={<AdminDevices />}></Route>
            <Route path="admin-users" element={<AdminUsers />}></Route>
            <Route path="active_connections" element={<ActiveConnections />}></Route>
            <Route path="mydevice-register" element={<BatchRegistration />}></Route>

            <Route path="omc-settings" element={
                <SchemaModal key="settings" SchemaUrl="/rest/omc/generalparameters?get_schema=dassui,put" type="page"
                             EditObject={{ __readonly: Object.keys(appContextObj.user || {}).includes("omc_mng_general_settings")}}
                             OnClose={() => { appContextObj.loadUser(); navigate(-1);} }
                             breadCrumbArr={[ { label: strings.NAV_NST_GENERAL_SETTINGS, url: "" } ]} />} 
            />
            
            <Route path="omc-gateway-defaults" element={
                <SchemaModal key="default-settings" SchemaUrl="/rest/omc/rfconfigs?get_schema=dassui,put" type="page"
                            breadCrumbArr={[{label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`},{ label: "Default Gateway LoRaWAN configuration", url: "" }]} OnClose={() => navigate(-1) }/>} 
            />
            <Route path="server-versions" element={
                <SchemaModal key="versions" SchemaUrl="/rest/omc/info?get_schema=dassui,put" type="page"
                        breadCrumbArr={[ { label: strings.NAV_NST_SERVER_VERSIONS, url: "" } ]} OnClose={() => navigate(-1) } />} 
            />

            <Route path="server-alarms" element={<Alarms key="server" alarmtype="server" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={ServerAlarmRowProps} />} />
            </Route>

            <Route path="gateway-alarms" element={<Alarms key="gateway" alarmtype="gateway" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={GatewayAlarmRowProps} />} />
            </Route>

            {/* <Route path="servers-status" element={<ServersStatus />} /> */}


            <Route path="servers-status" element={<ServersStatus />} >
            <Route path=":id/:tabname" element={<DetailPage detailProps={ServerStatusProps} />} />
            </Route>        

            <Route path="gateway-tags" element={<GatewayTags />} >
                <Route path=":tabname" element={<DetailPage key="add" detailProps={GatewayTagsProps} />} />
                <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={GatewayTagsRowProps} />} />
            </Route>

            <Route path="gateway-tag-category" element={<GatewayTags />} >
                <Route path=":tabname" element={<DetailPage key="add" detailProps={GatewayTagCategorysProps} />} />
                <Route path="delete/:tabname" element={<DetailPage key="delete" detailProps={GatewayTagCategorysDeleteProps} />} />
            </Route>

            <Route path="gateway-parameters" element={<GatewayParameters />} >
                <Route path=":tabname" element={<DetailPage key="add" detailProps={GatewayParametersProps} />} />
                <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={GatewayParametersRowProps} />} />
            </Route>

            <Route path="blocked-gateways" element={<BlockedGateways />} >
                <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={BlockedGatewaysRowProps} />} />
            </Route>


            <Route path="gateway-audit-trail" element={<GatewayAutitTrail />} />

            <Route path="gateway-manage-auth" element={<GatewayManageAuth />} >
                <Route path=":tabname" element={<DetailPage detailProps={GatewayManageAuthProps} />} />
            </Route>
            

            <Route path="gateway-jobs" element={<GatewayJobs />}>
                    <Route path=":id/:tabname" element={<DetailPage  detailProps={GatewayJobsRowProps} />} />
                </Route>

            <Route path="tenants" element={<Tenants />}>
                <Route path=":tabname" element={<DetailPage key="add" detailProps={TenantProps} />} />
                <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={TenantRowProps} />} />
            </Route>

            <Route path="omc-gateways" element={<OmcGateways />}>

                <Route path=":id/:tabname" element={<DetailPage  detailProps={OmcGatewayRowProps} />} />
                <Route path=":id/:tabname/:map" element={<DetailPage  detailProps={OmcGatewayMapRowProps} />} />
                <Route path=":tabname" element={<DetailPage  detailProps={OmcGatewayProps} />} />
                <Route path="bulkaction/:tabname" element={<DetailPage  detailProps={GatewayBulkActionProps} />} />
            </Route>

            <Route path="omc-devices" element={<OmcDevices />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={OmcDeviceRowProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={OmcDeviceProps} />} />
            </Route>
            

            <Route path="omc-relays" element={<OmcRelays />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={OmcRelayRowProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={OmcRelayProps} />} />
            </Route>


            <Route path="user-settings" element={
                    <SchemaModal key="user-settings"
                        breadCrumbArr={[{ label: strings.NAV_USER_SETTINGS, url: "" }]}
                        SchemaUrl={`/rest/${ appContextObj.accountType(appContextObj.user)}s?get_schema=dassui,put`}
                        EditObject={{ userid: appContextObj.user?.loginid || appContextObj.user?.userid, _editself: true}}
                        OnClose={(user, update) => { if (update) { appContextObj.loadUser(); } navigate(-1);} }
                        type="page" loadDataOnOpen={true} />}
            />

            <Route path="product-license" element={
                    <SchemaModal key="product-license" SchemaUrl="/rest/license?get_schema=dassui,put" type="page"
                        breadCrumbArr={[{label: strings.PRODUCT_LICENSE_TITLE, url: ''}]}
                        EditObject={{}}
                        OnClose={() => { appContextObj.loadUser(); navigate(-1); }} />}
            />

            <Route path="eula" element={
                <SchemaModal key="eula" SchemaUrl="/rest/users?get_schema=dassui,eula" type="page"
                                breadCrumbArr={[{label: strings.NAV_SOFTWARE_INFO, url: ''}]}
                                OnClose={() => navigate(-1)} />}
            />

            <Route path="my-campaigns" element={<MyCampaigns></MyCampaigns>}></Route>


        </Route >
    )
}
