import React, {useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams, /*useNavigate*/ } from 'react-router-dom';
import { strings } from "./../../services/Localization";
import { checkLogin, SignInForward } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import PageContent from "../PageContent";
// import { dialog } from '../../components/Common';
import { dateTimeString, actionIcon } from '../../utils/filters';
import { IUser } from "src/dassTypes";
import { IRowType } from "../Devices/DmpDevices";
import { appBaseUrl, getAppBase, getLabelBasedOnServerType, nstBaseUrl } from '../../utils/consts';
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { faRefresh }  from '@fortawesome/pro-solid-svg-icons';
import{ DEFAULT_RECORD_LIMIT, DEVEUI_INPUT_VALIDATION }  from "../../components/Common/DataTable/DataTableConsts";
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { ActionType, ColumnType, DataTableOption } from "src/components/Common/DataTable/DataTypes";
import { isMobile } from "react-device-detect";

// type IRowType = IDevice;
declare const constants;


interface IAdminDevicesState {
    loggedUser: IUser;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    refresh: boolean;

}

const AdminDmpDevices: React.FC<{}> = () =>  {

    const location = useLocation();
    const isNst = String(location.pathname).indexOf('/nst') >= 0;
    const baseUrl = isNst ? nstBaseUrl : appBaseUrl;
    const navigate = useNavigate();
    const AppContextObj =  useContext(AppContext);

    let { id, tabname } = useParams();
   
    const stateInit = {
        loggedUser: AppContextObj.user,
        editUserId:'',
        pageTitle: strings.FIND_DEVICES_TITLE,
        showAlertModal: false,
        breadCrumbArr: [getLabelBasedOnServerType(strings.NAV_ADMINISTRATIVE, strings.FIND, ''),{label:strings.NAV_ADMIN_DEVICES, url:''}],
        refresh:false
    };
    
    const[state, setState] = useState<IAdminDevicesState>(stateInit)
    
    // const can_list_profile = "can_list_profile";

    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {

            //LoggedIn();
            if(checkLogin(AppContextObj.user)) {
                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
            }
        }
    },[AppContextObj.user?.userid])


    
    const signInForward = async (userid: string) => {
        try {
            const user = await SignInForward(userid, baseUrl)
            AppContextObj.updateUser(user);
            return user;
        } catch (e)  {
            console.log(e.message);
            return null;
        }
	};
    
    const getActions = () => {
        // const user = AppContextObj.user;

        const actions: ActionType<IRowType>[] = [
            {
                type: "action",
                text: "Impersonate owner",
                render: (row, title) => actionIcon(row.deviceUuid, title, faArrowRight.iconName),
                action: (row) => signInForward(row.useridx),
                visible: (row) => AppContextObj.user.userid != row?.useridx ? true : false
            }
        ]

        return actions;
    }

    const detailPageNav = async ( navigate, tab, deveui, row) => {

        const prevPageUrl =  String(location.pathname);
        if (AppContextObj.user.userid !== row.ownerid) {
                // Here as per the implementation when user is able to find device he will able to impersonate too
                signInForward(row.ownerid).then((success) => {
                    if(success){
                        navigate(`${getAppBase()}/dmp-devices/${deveui}/${tab}`, {state: {row: row, prevPageUrl}})
                    }
                })
            // }
        } else {
            navigate(`${getAppBase()}/dmp-devices/${deveui}/${tab}`, {state: {row: row, prevPageUrl}})
        }
        
    }

    const getTableOptions = () => {
		
        const actions = getActions();

		const columns: ColumnType<IRowType>[] = [
            {
              key: "deviceUuid",
              type: "text",
              title: "Device UUID",
              maxInputLength:40,
              filterable: true,
              filterField: "search_deviceuuid",
              filterType: "text",
              inputValidation: DEVEUI_INPUT_VALIDATION,
              sortable: true,
              sortKey: "sort_by_deviceuuid",
              copyLink: true,
              newCellWidth: isMobile ? '250px' : "20%"
          }, {
            key: "name",
            title: "Name",
            detailPageNav: (row) =>  detailPageNav(navigate, "device-detail", row.deviceUuid, row ),
            type: "text",
            filterable: false,
            detailLink: true,
            filterField: "search_name",
            filterType: "text",
            sortable: false,
            sortKey: "sort_by_name",
            filterParams: {
                mapper: (x) => x || undefined
            },
            newCellWidth: "27%",
        }, {
                key: "description",
                title: strings.DESCRIPTION,
                detailPageNav: (row) =>  detailPageNav(navigate, "device-detail", row.deviceUuid, row ),
                type: "text",
                filterable: false, // false for find devices
                detailLink: true,
                filterField: "search_description",
                filterType: "text",
                sortable: false,
                sortKey: "sort_by_description",
                filterParams: {
                    mapper: (x) => x || undefined
                },
                newCellWidth: "27%",
            }, {
                key: "last_update_tmst",
                title: strings.LAST_SEEN,
                type: "text",
                render: x => dateTimeString(x.last_update_tmst),
                cellWidth: 34,
                customClass: 'font-monospace nowarp',
                newCellWidth: "10%",
    
            }, {
                key: "useridx",
                title: strings.OWNER,
                type: "text",
                dataAlign: "center",
                newCellWidth: "10%"
            }
        ];

        const options: DataTableOption<IRowType> = {
            url: '/uiapi/rest/nodes',
            query_param: {all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress', search_all_customers: true, search_all_tenants:true, search_all_users:true},
            serial_number: false,
            id_field: 'deviceUuid',
            oboe_path: 'pages.*',
            available_key: 'deviceUuid',
            columns,
            isDisplayMobile:true,            
            actions,
        }

        return options;
	}

    const refreshTable = () => {

        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
        
    }
    
    const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            },
        ]

        return pageButtons;
    }

    if (constants.enable_profiles !== true) {
        return null;
    }else {
        return (<PageContent
            name={`admin-devices`} 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={state.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            emptyDataMsg={strings.NO_DEVICE_AVAILABLE}
            countLabel={`Devices`} 
            dataTableOption={getTableOptions()} 
            refresh={state.refresh}>
        </PageContent>)
      
    }   
        
}


export default AdminDmpDevices;