import React, { useEffect, useState, useContext } from "react";
import { strings } from "../../services/Localization";
import { checkLogin } from "../../services/Login";
import { useParams } from 'react-router-dom';
import { IUser } from "../../dassTypes";
import {  dialog, dialogDescription } from '../../components/Common';
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { ActionType, BulkActionType, ColumnType, DataTableOption  } from '../../components/Common/DataTable/DataTypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "../../utils/Toaster";
import { getVisibleActions, actionIcon, dateTimeString } from "../../utils/filters";
import{ ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT, DEFAULT_INPUT_VALIDATION }  from "../../components/Common/DataTable/DataTableConsts";
import PageContent from "../PageContent";
import { faTrashCan, faRefresh, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { trustedFormatText } from "../../schemaengine/client/SchemaTextParser";
import { DEVICE_JOB_STATUS_OPTIONS,  UUIDCOLWIDTH } from "../../utils/consts";
import { isMobile } from "react-device-detect";



interface IJobStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    isRowDeleted?:boolean;
}

interface IRowType {

  jobUuid: string;
  type: string;
  deviceUuid: string;
  deviceName: string;
  serviceName: boolean;
  creation_time: string;
  scheduleStarttime: string;
  schedulePeriodicInterval: string;
  status: string;
}


const DeviceJobs: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);
    let { id, tabname } = useParams();
    const stateInit = {
        loggedUser: AppContextObj.user,
        pageTitle: strings.NAV_MANAGE_JOBS,
        showAlertModal: false,
        editUserId: '',
        breadCrumbArr: [{label: strings.NAV_DEVICE_MANAGEMENT, url:''}, {label: strings.NAV_MANAGE_JOBS, url:''}],
        refresh:false,
        isRowDeleted:false
    };

    const [deviceJobState, setDeviceJobState] = useState<IJobStates>(stateInit)

    useEffect(() => {

        if(checkLogin(AppContextObj.user)) {
            setDeviceJobState(prevState => { return {...prevState, loggedUser: AppContextObj.user }})
        }

    },[AppContextObj.user?.userid, deviceJobState.refresh])


   const getActions = () => {

        let actions: ActionType[] = [
        {
            type: "action",
            text: strings.DELETE_JOB,
            visible: () => (AppContextObj?.user?.can_register && !AppContextObj?.user?._readonly),
            render: (row, title) => actionIcon(row.jobUuid, title, faTrashAlt.iconName),
            action: (job) => deleteJob(job)
        }]


        const bulkActions: BulkActionType<IRowType>[] = [
          {
              type: "header",
              text: "Default Actions"
          },
          {
              type: "action",
              text: strings.DELETE_JOBS,
              render: (row, title) => actionIcon(row.jobUuid, title, faTrashCan.iconName),
              action: (selectedIds) => deleteJobInBulk(selectedIds),
              visible: () => { 
                  return AppContextObj.user?.account_status === "active" 
                         && !AppContextObj.user?._readonly
                         &&  AppContextObj.user?.can_register
              } 
          }];

        return {
            actions: actions,
            bulkActions:bulkActions
        }
        
    }


    const deleteJobInBulk =  async (selectedRows: IRowType[]) => {
        // const names = selectedRows.map((row) => {
        //     return row['serviceName'];
        // })
        const jobUuids = selectedRows.map((row) => {
            return row['jobUuid'];
        })
        if(jobUuids && jobUuids.length > 0) {
            const confirmDialogeSettings = {
                title:  strings.BULK_DELETE_CONFIRM_DIALOAG_JOBS,
                description: await dialogDescription(jobUuids) + "",
                actionLabel: strings.ACTION_DELETE,
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(jobUuids.map((jobUuid) => {
                        return GenericDassQuery("/rest/jobs/" + jobUuid, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable(true);
                        toast.success(strings.BULK_DELETE_JOB_SUCCESS_MESSAGE);
                    });
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }


   const deleteJob =  async (job: IRowType) => {
        const confirmDialogeSettings = {
            title:  strings.DELETE_CONFIRM_DIALOG_JOB + ' "' + job.serviceName + `"`,
            description: `For Device "${job.deviceName || job.deviceUuid}"`,
            actionLabel: strings.ACTION_DELETE,
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {

                Promise.resolve(GenericDassQuery("/rest/jobs/" + job.jobUuid, { method: "DELETE" })).then((values) => {

                    refreshTable(true);
                    toast.success(strings.DELETE_JOB_SUCCESS_MESSAGE);

                });
               
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

    function cronToReadableString(cronString, scheduleStartTime) {
        if(!cronString) {
            return `Once at ${scheduleStartTime}`;
        }
        const cronParts = cronString.split(' ');
    
        if (cronParts.length !== 5) {
            return "";
        }
    
        const [minute, hour, dayOfMonth, month, dayOfWeek] = cronParts;
    
        const months = ["every month", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    
        let readable = "";
    
        // Convert hour and minute to local time (assuming UTC cron)
        let cronDate = new Date();
        cronDate.setUTCMinutes(minute === "*" ? 0 : parseInt(minute, 10));
        cronDate.setUTCHours(hour === "*" ? 0 : parseInt(hour, 10));
    
        const timeFormatter = new Intl.DateTimeFormat(undefined, {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true, // Optional: Set to true for 12-hour format, false for 24-hour format
            timeZoneName: 'short'
        });
    
        const localTimeString = timeFormatter.format(cronDate);
    
        // If minute or hour is '*', we adjust the readable string
        if (minute === "*" && hour === "*") {
            readable += "Every minute";
        } else if (minute === "*" && hour !== "*") {
            readable += `At the start of every minute of the hour ${hour}:00 (${localTimeString})`;
        } else if (minute !== "*" && hour === "*") {
            readable += `At ${minute} minutes past every hour (${localTimeString})`;
        } else {
            readable += `At ${minute} minutes past ${hour}:00 (${localTimeString})`;
        }
    
        // Check for specific cases to add daily, weekly, or monthly
        if (dayOfMonth === "*" && dayOfWeek === "*" && month === "*") {
            if (readable !== "Every minute") readable += " daily";
        } else if (dayOfMonth === "*" && month === "*" && dayOfWeek !== "*") {
            readable += ` every ${daysOfWeek[parseInt(dayOfWeek, 10)]}`;
        } else if (dayOfMonth !== "*" && dayOfWeek === "*" && month === "*") {
            readable += " every month";
            readable += ` on day ${dayOfMonth}`;
        } else if (dayOfMonth === "*" && dayOfWeek === "*" && month !== "*") {
            readable += ` every year in ${months[parseInt(month, 10)]}`;
        }
    
        // Handle specific day of the week or month
        if (month !== "*" && readable.indexOf("every year") === -1) {
            readable += ` in ${months[parseInt(month, 10)]}`;
        }
    
        if (dayOfWeek !== "*" && readable.indexOf("every") === -1) {
            readable += ` on ${daysOfWeek[parseInt(dayOfWeek, 10)]}`;
        } else if (dayOfMonth !== "*" && readable.indexOf("every month") === -1) {
            readable += ` on day ${dayOfMonth}`;
        }
    
        return readable.trim();
    }
    
  
  type FilterOptionType = {
    label: string;
    value: string;
    }

  function filterJobStatus():FilterOptionType[] {
    return JSON.parse(JSON.stringify(DEVICE_JOB_STATUS_OPTIONS));
  }

   const initDataTable = () => {
        
        const {actions, bulkActions} = getActions();

        const columns: ColumnType[] = [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 3,
                newCellWidth: "30px",
                customClass: 'sticky left-first',
            },
            {
              key: 'jobUuid',
              type: "text",
              title: strings.TABLE_UUID,
              render: (row) => (isMobile ? row.jobUuid : trustedFormatText(`[[fa-ellipsis]]${row.jobUuid.slice(-6)}`, row.jobUuid, null, null)),
              cellWidth: 3,
              newCellWidth: (isMobile ? UUIDCOLWIDTH : '90px'),
              copyLink: true,
              customClass: 'font-monospace fa-80 sticky left-second',
              render_tooltip: (row) => row.jobUuid,
              filterable: true,
              filterField: "search_jobUuid",
              sortable: true,
              sortKey: "sort_by_jobUuid",
              filterType: "text",
              dataAlign: "center",
              inputValidation: ID_INPUT_VALIDATION

          },
          {
            key: 'deviceUuid',
            type: "text",
            title: strings.DEVICE_UUID,
            render: (row) => (isMobile ? row.deviceUuid : trustedFormatText(`[[fa-ellipsis]]${row.deviceUuid.slice(-6)}`, row.deviceUuid, null, null)),
            newCellWidth: (isMobile ? UUIDCOLWIDTH : '150px'),
            copyLink: true,
            customClass: 'font-monospace fa-80 sticky left-second',
            render_tooltip: (row) => row.deviceUuid,
            filterable: true,
            filterField: "search_deviceUuid",
            extraClass: 'ow-datatable-overflow-ellipsis',
            sortable: false,
            sortKey: "sort_by_deviceuuid",
            filterType: "text",
            dataAlign: "center",
            inputValidation: ID_INPUT_VALIDATION

        },
        {
            key: "deviceName",
            title: strings.DEVICE_NAME,
            type: "text",
            filterable: true,
            filterField: "search_device_name",
            filterType: "text",
            inputValidation: DEFAULT_INPUT_VALIDATION,
            sortable: false,
            sortKey: "sort_by_name",
            filterParams: {},
            cellWidth: 50,
            newCellWidth: isMobile ? '180px' : '250px',
            dataAlign:'left',
            extraClass: 'ow-datatable-overflow-ellipsis',
            render_tooltip: (row) => row.name
        }, 
          {
                key: "serviceName",
                type: "text",
                title: strings.SERVICE_NAME,
                filterable: true,
                filterField: 'search_service_name',
                filterType: 'text',
                sortKey: "sort_by_service_name",
                sortable: false,
                newCellWidth: '120px',
                inputValidation: ID_INPUT_VALIDATION,
                filterParams: {
                    mapper: (x) => x || undefined
                },
                extraClass: 'ow-datatable-overflow-ellipsis',
                customClass: 'nowarp',

            },
            {
              key: "status",
              title: strings.DEVICE_JOB_STATUS,
              type: "text",
              filterable: true,
              filterField: "search_job_status",
              filterType: 'multiselect',
              inputValidation: DEFAULT_INPUT_VALIDATION,
              sortable: false,
              sortKey: "sort_by_job_status",
              cellWidth: 50,
              dataAlign:'center',
              newCellWidth: "120px",
              filterParams: {
                  mapper: (x) => {
                      return x || undefined
                  },
                  optionFetcher: filterJobStatus
              }
          }, 
          {
            key: "scheduleStartTime",
            title: strings.DEVICE_JOB_SCHEDULE_STARTTIME,
            type: "text",
            filterable: false,
            filterField: "search_job_start_time",
            filterType: "date",
            inputValidation: DEFAULT_INPUT_VALIDATION,
            sortable: true,
            sortKey: "sort_by_start_time",
            filterParams: {},
            cellWidth: 50,
            dataAlign:'center',
            render: x => dateTimeString(x.scheduleStartTime),
            newCellWidth: "185px"
        }, 
        {
          key: "scheduleEndTime",
          title: strings.DEVICE_JOB_SCHEDULE_ENDTIME,
          type: "text",
          filterable: false,
          filterField: "search_job_end_time",
          filterType: "date",
          inputValidation: DEFAULT_INPUT_VALIDATION,
          sortable: true,
          sortKey: "sort_by_end_time",
          filterParams: {},
          cellWidth: 50,
          dataAlign:'center',
          render: x => dateTimeString(x.scheduleEndTime),
          newCellWidth: "185px"
      }, 
      {
        key: "errorMessage",
        title: strings.ERROR_MESSAGE,
        type: "text",
        filterable: false,
        sortable: false,
        sortKey: "sort_by_name",
        cellWidth: 50,
        newCellWidth:  '250px',
        dataAlign:'left',
        extraClass: 'ow-datatable-overflow-ellipsis',
        render_tooltip: (row) => row.errorMessage
    }, 
        {
          key: "schedulePeriodicInterval",
          title: strings.DEVICE_JOB_SCHEDULE_PERIODICITY,
          type: "text",
          filterable: false,
          filterField: "search_job_status",
          filterType: "text",
          inputValidation: DEFAULT_INPUT_VALIDATION,
          sortable: false,
          sortKey: "sort_by_job_status",
          filterParams: {},
          cellWidth: 50,
          dataAlign:'left',
          render: x => cronToReadableString(x.schedulePeriodicInterval, dateTimeString(x.scheduleStartTime)),
      }, 
        ];

        columns.push({
            key: 'action_button',
            type: "action_button",
            title: 'Actions',
            filterable: false,
            cellWidth: 3,
            newCellWidth: "140px",
            customClass: 'sticky right',
        });


        const options:DataTableOption<IRowType> = {
            
            url:'/uiapi/rest/jobs',
            query_param:{ all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number:false,
            id_field:'jobUuid',
            oboe_path:'pages.*',
            available_key: 'jobUuid',
            modal:false, 
            columns,
            actions: actions,
            defaultSortField: 'sort_by_start_time',
            defaultSortOrder: 'desc',
            emptyDataMsg: strings.NO_JOB_AVAILABLE,
            bulkActions: getVisibleActions(bulkActions)

        }

        return options;
    }
    
 

    const refreshTable = (isRowDeleted=false) => {

        setDeviceJobState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })
        
    }
    
   const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
              
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            }
        ];

        return pageButtons;
    }


    return (
        <PageContent
            name="my-jobs" 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={deviceJobState.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={strings.NAV_MANAGE_JOBS} 
            dataTableOption={initDataTable()} 
            isRowDeleted={deviceJobState.isRowDeleted}
            refresh={deviceJobState.refresh}>
        </PageContent>
    );

}


export default DeviceJobs;
