import React, { useState, useEffect } from 'react';

const DebugViewPage = () => {
    const [debugInfo, setDebugInfo] = useState<any>({});

    // Function to gather device, browser, and screen information
    const collectDebugInfo = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const userAgent = navigator.userAgent;
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const isMobile = /Mobi|Android/i.test(userAgent);
        const devicePixelRatio = window.devicePixelRatio;
        const platform = navigator.platform;
        const browserLanguage = navigator.language;
        const cookieEnabled = navigator.cookieEnabled;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        

        setDebugInfo({
            width,
            height,
            screenWidth,
            screenHeight,
            userAgent,
            isMobile,
            devicePixelRatio,
            platform,
            browserLanguage,
            cookieEnabled,
            timezone
        });
    };

    // Use Effect to gather initial information and update on resize
    useEffect(() => {
        collectDebugInfo();
        window.addEventListener('resize', collectDebugInfo);
        return () => {
            window.removeEventListener('resize', collectDebugInfo);
        };
    }, []);

    return (
        
                <div style={{ marginTop: '20px', border: '1px solid #ccc', padding: '10px' }}>
                    <h3>Debug Information</h3>
                    <p><strong>Screen Width:</strong> {debugInfo?.screenWidth}px</p>
                    <p><strong>Screen Height:</strong> {debugInfo?.screenHeight}px</p>
                    <p><strong>Window Width:</strong> {debugInfo?.width}px</p>
                    <p><strong>Window Height:</strong> {debugInfo?.height}px</p>
                    <p><strong>User Agent:</strong> {debugInfo?.userAgent}</p>
                    <p><strong>Is Mobile:</strong> {debugInfo?.isMobile ? 'Yes' : 'No'}</p>
                    <p><strong>Device Pixel Ratio:</strong> {debugInfo?.devicePixelRatio}</p>
                    <p><strong>Platform:</strong> {debugInfo?.platform}</p>
                    <p><strong>Browser Language:</strong> {debugInfo?.browserLanguage}</p>
                    <p><strong>Cookies Enabled:</strong> {debugInfo?.cookieEnabled ? 'Yes' : 'No'}</p>
                    <p><strong>Timezone:</strong> {debugInfo?.timezone}</p>
                </div>
         
    );
};


export default DebugViewPage;