import { strings } from "../../services/Localization";
import React, { useEffect } from "react";
import { DataTable } from '../../components/Common';

import  { dateTimeString, getVisibleActions  } from '../../utils/filters';

import { PageButtonType} from '../../datatypes/datatypes';

import { faPaperPlane , faTrashAlt, faRefresh, faFileExport //faMapLocation
		 } from '@fortawesome/pro-regular-svg-icons'
import { ActionType, BulkActionType, ColumnType, DataTableOption } from "src/components/Common/DataTable/DataTypes";

import{ DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";

import { IDevice, IUser } from "../../dassTypes";

import { GenericDassQuery } from "../../services/BasicDassQueries";

import { SpinnerHide, SpinnerShow, dialog } from "../../components/Common";

import { toast } from "../../utils/Toaster";
import { actionIcon } from "../../utils/filters";
import { isMobile } from "react-device-detect";

interface IRowType {
	timestamp: string;
	payload: string;
	id: number;
	topic: string;
	decoded: object;
}



function renderDecodedPayloadTo(decodedObj: object): JSX.Element {

	if (!decodedObj) { return null; }
	const newDecodedObj = {};

	Object.keys(decodedObj).forEach((key) => {
		if (decodedObj[key] != null){
			newDecodedObj[key] = decodedObj[key];
		}
	});

	// finding the key with most characters
	let longestKeyLength = null;
	Object.keys(newDecodedObj).forEach((key) => {

		if (!longestKeyLength) {
			longestKeyLength = key.length;
		}
		else if (key.length > longestKeyLength) {
			longestKeyLength = key.length;
		}
	});

	let html = "";
	Object.keys(newDecodedObj).forEach(function (key) {

		var keyString = key;
		var keyLength = keyString.length;
		for (var i = keyLength; i < longestKeyLength; i++) {
			keyString += ' ';
		}

		var result;
		if (typeof newDecodedObj[key] === "object") {
			var jsonStringify = JSON.stringify(newDecodedObj[key]).replace(/,/g, ", ");
			var strReplace = jsonStringify.replace(/\"([^(\")"]+)\":/g,"$1:").slice(1, -1);
			result = strReplace.replace(/\"|'|null| _/gi, "");
		} else {
			result = newDecodedObj[key];
		}
		html += '<div class="row"><div class="col-md-12 key-container">' + keyString + ' = ' + result + '</div></div>';
	});

	return <div className="column-inside-container container" dangerouslySetInnerHTML={{__html: html}} />;
}

interface DeviceDataState {
	device: IDevice;
	exportInit: number;
	user: IUser;
	groups: any;
	apps: any;
	refresh: boolean;
	isRowDeleted?:boolean;
}

const DmpDeviceViewDataTable:React.FC<{ deviceUuid: string, user: IUser }> = ( props ) =>  {
	const  deviceUuid = props.deviceUuid;
	const initState = {
		exportInit: 0,
		device: null,
		user: props.user,
		groups: [],
		apps: [],
		refresh: false,
		isRowDeleted:false
	}
	const [state, setState] = React.useState<DeviceDataState>(initState);
  


    const  getActions = () => {

		const actions: ActionType[] = [{
			type: "action",
			text: strings.DATA_DELETE_PACKET,
			render: (row, title) => actionIcon(row.timestamp, title, faTrashAlt.iconName),
			action: (packet) => deletePayload(packet),
			visible: () => !state.user?._readonly
		}, {
			type: "action",
			text: strings.RE_PUSH_PAYLOAD,
			render: (row, title) => actionIcon(row.timestamp, title, faPaperPlane.iconName),
			action: (packet) => rePushPayloadUL(packet),
			visible: (payload) => !state.user?._readonly
		}];

		const bulkActions: BulkActionType[] = [{
			type: "action",
			text: strings.DATA_DELETE_PACKETS,
			render: (row, title) => actionIcon(row.timestamp, title, faTrashAlt.iconName),
			action: (packets) => deletePayloads(packets),
			visible: () => state.user && !state.user?._readonly
		}];

		return {actions, bulkActions };
    }


	const  getDeviceDetails = async () => {
		try {
			const req = await GenericDassQuery(`/rest/nodes/${deviceUuid}?all=true`);
			if (req.status === 200) { 
				state.device = req.data ;
				setState(prevState => {
					return {...prevState, device:req.data}
				})
			}
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		getDeviceDetails();
	},[]);



	const  getColumns = () => {
		
		const columns: ColumnType<IRowType>[] = [
			
			
			{
				key: "timestamp",
				dbKey: "timestamp",
				title: strings.TIME,
				type: "text",
				render: (x) => dateTimeString(x.timestamp),
				filterable: true,
				filterField: "date",
				filterType: 'daterange',
				filterParams: {
					startField: "payload_from_date",
					endField: "payload_to_date",
				},
				sortable: true,
				newCellWidth: "15%",
				sortKey: "sort_by_timestamp",
				cellWidth: "nowrap",
				customClass: "text-nowrap"
			},		
			{
				key: "topic",
				dbKey: "timestamp",
				title: strings.SHOW_DMP_DEVICE_TOPIC,
				type: "text",
				dataAlign: "center",
				render: (x) => ((x.topic) ? x.topic : ""),
				enabledInColumn: true,
				newCellWidth: isMobile ? '100px' : '15%',
				customClass: 'font-monospace'

			},			
			{
				key: "decoded",
        dbKey: "timestamp",
				title: strings.SHOW_DMP_DEVICE_MESSAGE,
				type: "text",
				render: row => renderDecodedPayloadTo(row.decoded),
				cellWidth: 10,
				enabledInColumn: true,
				newCellWidth: "60%",
			}
        ];
		return columns;
	}

    const  getDataTableOptions = () => {
        const actions = getActions();
		    const columns = getColumns();
        const endpoint = `uiapi/rest/export/payloads?download_filename=payloads.csv&deviceuuids=${deviceUuid}` ;
        let options: DataTableOption = {
			url: `/uiapi/rest/nodes/${deviceUuid}/payloads/all`,
            query_param: { all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress', sort_by_timestamp: 'desc', mac_msg: false },
            serial_number: false,
            id_field: 'id',
            oboe_path: 'pages.*',
            available_key: 'deviceUuid',
			allowBulkActions: true,
            defaultSortField: 'sort_by_deviceuuid',
            defaultSortOrder: 'asc',
			columns,
			actions: actions.actions,
            bulkActions: getVisibleActions(actions.bulkActions),
			countLabel:'Messages Stored',
            exportPath: endpoint,
        }

        return options;
	}

    const  refreshTable = (isRowDeleted=false) => {

		setState(prevState => {
            let refresh = (prevState.refresh === true)  ? false : true;
            return {...prevState, refresh:refresh, isRowDeleted:isRowDeleted}
        })
		
	}

	const exportTable = () => {
        
        setState(prevState => {
            let exportInit = (prevState.exportInit === 1)  ? 2 : 1;
            return {...prevState, exportInit:exportInit}
        })
    }

    const  getPageButtons = () => {

		const pageButtons:PageButtonType[] = [
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'refresh',
                icon: faRefresh
            },
            {
                title: strings.EXPORT_DEVICE_BTN,
                action: (  ) => { exportTable() },
                type: 'button',
				icon: faFileExport,
            }
          
        ]

        return pageButtons;
    }

    const deletePayload = async (packat) => {

		const confirmDialogeSettings = {
            title:  "You are about to delete a payload",
            description: "",
            actionLabel: 'Delete',
        };
		if(await dialog(confirmDialogeSettings) === true) {
			SpinnerShow();
			let url = `uiapi/rest/nodes/${deviceUuid}/payloads/ul/${packat.id}`;
			try {
				await GenericDassQuery(url, {prefix: '/', method: "DELETE"});
				SpinnerHide();
				toast.success(`Packet Deleted`)
				refreshTable(true);

			} catch (e) {
				toast.error(e)
			}
	    }
	}

    const rePushPayloadUL = async ( packet ) => {
		try {
			SpinnerShow();
			
			const url = `uiapi/rest/pushmode/test/payload_ul/${deviceUuid}/${packet.id}`

			await GenericDassQuery(url, {prefix: '/', method: "POST"});
			toast.success(`Payload re-pushed successfully`)
			SpinnerHide();
			// refreshTable();

		} catch (e) {
			toast.error(e)
  		}
	}


    const deletePayloads = async ( selectedRows: IRowType[] ) => {

		if( selectedRows && selectedRows.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete the selected packet(s)`,
                description:'',
                actionLabel: 'Delete',
            };
			if (await dialog(confirmDialogeSettings) === true) {
				SpinnerShow();
				let url;
				const promises = []
				selectedRows.map((packat ) => {
          url = `uiapi/rest/nodes/${deviceUuid}/payloads/ul/${packat.id}`;
					const prom =  GenericDassQuery(url, {prefix: '/', method: "DELETE"});
					promises.push(prom);
				})

				try {
					await Promise.all(promises) 
					toast.success(`Deleted ${selectedRows.length} packets for device`)
					SpinnerHide();
					refreshTable(true);
				} catch (e) { 
					toast.error(e)
				}	
			}
		}
	};

	return (<DataTable  isRowDeleted={state.isRowDeleted} exportInit={state.exportInit} refresh={state.refresh} name={`view-data-${deviceUuid}`} 
						countLabel={'Messages Stored'}  dataTableOption={getDataTableOptions()} pageButtons={getPageButtons()} scope="child" />)
}


export default DmpDeviceViewDataTable;