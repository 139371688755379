import { IJsonSchemaObject } from "../UiJsonSchemaTypes";

export interface ValueRef {
	field: string;
	datum: number | string;
    value: number | string;
	expr: string;

    scale: {
        domain: Array<number | string>;
        range:  Array<number | string>;
    }
}

export type Value = number | ValueRef


export interface VegaLikeSchemaBase {
	$schema: string;
    debug: Value;
    data: {
		values: any[];
	};
	transform: Array<{
		calculate: string;
		as: string;

		filter: string;

		pivot: string;
		value: string;
		groupby: string[],
		limit: number;
		op: string;

	}>;
	params: Array<{
		name: string;
		value: number | string;
        expr: string;
	}>;


    // internal
    __globobj__: any;

}

const $defs: {
    [ref: string]: IJsonSchemaObject;
} = {

    scaleRefDef: {
        type: "object",
        properties: {
            domain: {
                description: "values to match",
                type: "array",
                items: { type: ["number", "string"] }
            },
            range: {
                description: "values to replace matched values",
                type: "array",
                items: { type: ["number", "string"] }
            }

        },
        required: ["domain", "range"],
        additionalProperties: false,
    },
    fieldRefDef: {
        type: "object",
        properties: {
            field: {
                type: "string",
                description: "Name of field in the data where the value should be taken"
            },
            scale: { $ref: "#/$defs/scaleRefDef" }
        },
        required: ["field"],
        additionalProperties: false,
    },
    exprDef: {
        type: "object",
        properties: {
            expr: {
                type: "string",
                description: "Expression to be evaluated"
            }
        },
        required: ["expr"],
        additionalProperties: false,
    },
    valueDef: {
        type: "object",
        properties: {
            value: {
                type: ["string", "number"],
                description: "Constant value"
            }
        },
        required: ["value"],
        additionalProperties: false,
    },
    datumDef: {
        type: "object",
        properties: {
            value: {
                type: ["string", "number"],
                description: "datum constant value. Datum values as subject to scale"
            },
            scale: { $ref: "#/$defs/scaleRefDef" }
        },
        required: ["datum"],
        additionalProperties: false,
    },


    /**
     * useful combos
     */
    booleanRefExprDef: {
        oneOf: [{ type: "boolean" }, { $ref: "#/$defs/fieldRefDef" }, { $ref: "#/$defs/exprDef" }, { $ref: "#/$defs/valueDef" }]
    },

    numberRefExprDef: {
        oneOf: [{ type: "number" }, { $ref: "#/$defs/fieldRefDef" }, { $ref: "#/$defs/exprDef" }, { $ref: "#/$defs/valueDef" }]
    },

    integerRefExprDef: {
        oneOf: [{ type: "integer" }, { $ref: "#/$defs/fieldRefDef" }, { $ref: "#/$defs/exprDef" }, { $ref: "#/$defs/valueDef" }]
    },

    stringRefExprDef: {
        oneOf: [{ type: "string" }, { $ref: "#/$defs/fieldRefDef" }, { $ref: "#/$defs/exprDef" }, { $ref: "#/$defs/valueDef" }]
    },

    numberStringRefExprDef: {
        oneOf: [{ type: "number" }, { type: "string" }, { $ref: "#/$defs/fieldRefDef" }, { $ref: "#/$defs/exprDef" }, { $ref: "#/$defs/valueDef" }]
    }

}



export const vegaLikeJsonSchema: IJsonSchemaObject = {
	$id: "internal:vegalike",
	$schema: "http://json-schema.org/draft-07/schema",
	type: "object",
    $defs,

    properties: {

        $schema: {
            description: `[[@md]]Supported types are:
- internal:map - Map view with support for image overlays, points and paths.
- internal:markdown - Markdown text panel support embedded Mermaid figures, scientific formulas, and much more.
- internal:gauge - Gauge (aka fuel-gauge) style widget.
- internal:status-panel - Status panel showing one key information piece - typically a value.
- internal:table - Scrollable table with header and data rows.
`,
            type: "string",
        },


        transform: {
            type: "array",
            description: 
`[[@md]]#### Transformations
An array of data transformations such as filter and new field calculation. The following transformations are supported:
- Calculate
- Pivot
- Filter

Transformations is a concept borrowed from VegaLite, see [here](https://vega.github.io/vega-lite/docs/transform.html) for more details.
> Note: not all VegaLite transformations are supported.
            `,
            items: {
                type: "object",
                oneOf: [{
                    type: "object",
                    properties: {
                        calculate: {
                            type: "string", 
                            description: "Expression to be calculated for each element. To be used with 'as'"
                        },
                        as: {
                            type: "string",
                            description: "name where the result will be stored. For example if as='res', then the calculation can be accessed as datum.res"
                        },
                    },
                    required: ["calculate", "as"],
                    additionalProperties: false,
                }, {
                    type: "object",
                    properties: {
                        filter: {
                            type: "string",
                            description: "Expression used to filter. The expression must evaluate true to keep the data in the list",
                        }
                    },
                    required: ["calculate", "as"],
                    additionalProperties: false,

                }, {
                    type: "object",
                    properties: {
                        pivot: { 
                            description: `The data field to pivot on. The unique values of this field become new field names in the output stream.`,
                            type: "string" 
                        },
                        value: { 
                            description: `The data field to populate pivoted fields. The aggregate values of this field become the values of the new pivoted fields.`,
                            type: "string"
                        },
                        groupby: {
                            description: `The optional data fields to group by. If not specified, a single group containing all data objects will be used.`,
                            type: "array",
                            items: {
                                type: "string",
                            },
                        },
                        limit: {
                            description: 
`An optional parameter indicating the maximum number of pivoted fields to generate.
The default (0) applies no limit. The pivoted pivot names are sorted in ascending order prior to enforcing the limit`,
                            type: "number",
                        },
                        op: {
                            description: 
`[[@md]]The aggregation operation to apply to grouped value field values. Default value: sum. The following aggregators are available:
* sum - The sum of field values.
* mean - The mean (average) field value.
* average - The mean (average) field value (same as mean).
* count - The total count of data objects in the group.
* missing - The count of \`null\` or \`undefined\` field values.
* valid - The count of field values that are not \`null\`, \`undefined\` or \`NaN\`.
* distinct - The count of distinct field values.
* min - The minimum field value.
* max - The maximum field value.
* values - A list of data objects in the group.
* median - The median field value.
`,
                            type: "string",
                            enum: ["sum", "mean", "average", "count", "missing", "valid", "distinct", "min", "max", "values", "median"],
                        },
                    },
                    required: ["pivot", "value"],
                    additionalProperties: false,
                }],
            }
        },

        params: {
            description: 
`[[@md]]#### Parameters

~~~JSON
// A typical specification
{
    ...,
    "params": [  // An array of named parameters.
        {"name": ..., ...}
    ],
    "mark": ...,
    "encoding": ...,
    ...
}
~~~

Parameters are the basic building block in the grammar of interaction. Parameters can either be simple variables or more complex
expressions that combine data and other parameters. Parameters can be used throughout the remainder
of the specification to determine encoding rules, filter data points, determine data extents, or in expression strings.`,

            type: "array",
            items: {
                type: "object",
                properties: {
                    name: {
                        description: "Name of the parameter",
                        type: "string",
                        pattern: "^[a-zA-Z_][a-zA-Z0-9_]*$",
                    },
                    value: {
                        description: "Constant value or string for this parameter.",
                        type: ["string", "number"],
                    },
                    expr: {
                        description: "Expression that will be evaluated for this parameter.",
                        type: "string",
                    }
                },
                required: ["name"],
                additionalProperties: false,
            }
        }
    }
}
