import React, { ReactNode, useEffect, useRef, useState } from "react";
import ReactDOMServer from 'react-dom/server';

// textfit is an old library, and is not react compliant. However, it works, is still widely used, so
// it will do for now. There are no real viable alternatives available.
import * as textfit from "textfit";


import { IUiSchemaElemArgs } from "./SchemaController";
import { registerComponentHandler, registerJsonSchema } from "./SchemaExtensions";
import { readValue, vegaLikeTransforms } from "./VegaLikeTools";
import { DashboardStatusSchema, statusPanelJsonSchema } from "./ExtDashboardStatusTypes";

registerJsonSchema("internal:status-panel", ["*"], statusPanelJsonSchema);


interface DashboardStatusProps {
	id: string;
	text: ReactNode;
	subText: ReactNode;
}

function DashboardStatus(props: DashboardStatusProps) {

	const outerRef = useRef<HTMLDivElement>();
	const innerRef = useRef<HTMLDivElement>();
	const sub1Ref = useRef<HTMLDivElement>();
	const sub2Ref = useRef<HTMLDivElement>();
	const [resizeCnt, setResizeCnt] = useState(0);


	useEffect(() => {

		const outer = outerRef.current;
		const inner = innerRef.current;

		if (!outer || !outer.clientHeight || !outer.clientWidth) { return; }

		inner.style.width = outer.clientWidth + "px";
		inner.style.height = outer.clientHeight + "px";
		// sub1Ref.current.style.backgroundColor = "#10ffff";

		sub1Ref.current.style.width  = outer.clientWidth + "px";
		sub1Ref.current.style.height = (3 * outer.clientHeight / 4) + "px";

		sub2Ref.current.style.width  = outer.clientWidth + "px";
		sub2Ref.current.style.height = outer.clientHeight / 4 + "px";
		// sub2Ref.current.style.backgroundColor = "#ff10ff";

		try {

			textfit(sub1Ref.current, {
				alignVert: true, // if true, textFit will align vertically using css tables
				alignHoriz: true, // if true, textFit will set text-align: center
				multiLine: false, // if true, textFit will not set white-space: no-wrap
				detectMultiLine: false, // disable to turn off automatic multi-line sensing
				minFontSize: 5,
				maxFontSize: 60,
				reProcess: true,  // if true, textFit will re-process already-fit nodes. Set to 'false' for better performance
				widthOnly: false, // if true, textFit will fit text to element width, regardless of text height
				alignVertWithFlexbox: false, // if true, textFit will use flexbox for vertical alignment
			});

			textfit(sub2Ref.current, {
				alignVert: false, alignHoriz: true,
				multiLine: false, detectMultiLine: false,
				minFontSize: 2,
				maxFontSize: 15,
				reProcess: true, widthOnly: false,	alignVertWithFlexbox: false,
			});

		} catch (e) {
			console.log(e);
		}

	}, [resizeCnt, props.text, props.subText, outerRef.current?.clientWidth, outerRef.current?.clientHeight]);


	useEffect(() => {

		const resizeObserver = new ResizeObserver((entries) => setResizeCnt(prev => prev + 1));
		resizeObserver.observe(outerRef.current);
		return () => resizeObserver.disconnect();

	}, []);


	// Since the text in the target div will be parsed by the textfit library which is NOT a react library, 
	// we need to create the divs in a way that the sub-elements are not tracked by react. This is because
	// the textfit library may remove and add new children under the div. So we cannot create the text
	// fields in the normal way. Instead we render it to html and insert it as inner html.

	return <div style={{ position: "relative", width: "100%", height: "100%" }} ref={outerRef}>
			<div style={{ position: "absolute" }} ref={innerRef} >
				<div ref={sub1Ref} dangerouslySetInnerHTML={{__html: ReactDOMServer.renderToString(props.text)}}></div>
				<div ref={sub2Ref} dangerouslySetInnerHTML={{__html: ReactDOMServer.renderToString(props.subText)}}></div>
			</div>		
		</div>;

}







export const dashboardStatusExtension = (args: IUiSchemaElemArgs) => {

	const { value, title } = args;
	let text: string | ReactNode = "";
	let subText: string | ReactNode = "";

	try {
		if (value != null) {
			if (["string", "number", "boolean"].includes(typeof value)) {

				text = String(value);
				subText = title;

			} else if (typeof value === "object" && value.$schema) {
				let schema: DashboardStatusSchema = value;
				let idx = 0;

				let values = vegaLikeTransforms(schema.data?.values, schema.transform);
				if (values != schema.data?.values) {
					schema = { ...schema, data: { ...schema.data, values }};
				}

				const readFinalValue = (field: any, type: "string" | "number" | "any") => {
					const v = readValue(schema, field, type);
					return typeof v === "function" ? v(values[idx]) : v;
				}

				subText = readFinalValue(schema.encoding?.title, "string") as string;
				subText = subText != null ?args.stringToComponent(String(subText)) : null;

				text = readFinalValue(schema.encoding?.value, "string") as string;
				text = text != null ? args.stringToComponent(String(text)) : null;
			}
		}
	} catch (e) {
		text = "";
		subText = "";
	}

	return <DashboardStatus key={args.fullkey} id={args.fullkey} text={text} subText={subText}/>
};

registerComponentHandler("dashboard-status", dashboardStatusExtension);
