import { IJsonSchemaObject } from "../UiJsonSchemaTypes";
import { Value, VegaLikeSchemaBase, vegaLikeJsonSchema } from "./VegaLikeToolsTypes";

export interface DashboardStatusSchema  extends VegaLikeSchemaBase {
	$schema: "internal:status-panel" | "internal:v1/status-panel";
	encoding: {
		value?: Value;
		title?: Value;
	}
}




export const statusPanelJsonSchema: IJsonSchemaObject = {
	$id: "internal:status-panel",
	$schema: "http://json-schema.org/draft-07/schema",
	type: "object",
	$defs: {
		...vegaLikeJsonSchema.$defs,
	},
	properties: {

        ...vegaLikeJsonSchema.properties,

        encoding: {
			type: "object",
			description: ``,
			properties: {

				value: {
					description: `The key value (or text) that is shown in the middle of the card.`,
					$ref: "#/$defs/numberStringRefExprDef"
				},
				title: {
					description: `Title text that is shown below value`,
					$ref: "#/$defs/stringRefExprDef"
				}

			}
        },

	},
	required: ["$schema"],
    additionalProperties: false,
}
