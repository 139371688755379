import { strings } from "../../services/Localization";
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';
import { faAdd } from '@fortawesome/free-solid-svg-icons'
import { getAppBase, getLabelBasedOnServerType } from '../../utils/consts';
import { TAB_ADD_TAG, TAB_EDIT_TAG } from '../../datatypes/tabsconstants'



const detailPageNav = (detailPageNavArgs) => {

    const { navigate, tab, id, row } = detailPageNavArgs;
    let prevPageUrl = detailPageNavArgs.hasOwnProperty('detailPageNavArgs') ? detailPageNavArgs['prevPageUrl'] : null;
    if (id) {
        navigate(`${getAppBase()}/named-services/${id}/${tab}`, { state: { row: row, prevPageUrl: prevPageUrl } })
    } else {
        navigate(`${getAppBase()}/named-services/${tab}`, { state: { tab: tab, prevPageUrl: prevPageUrl } })
    }
}

export const NamedServicesProps: DetailPagePropsType = {
    getBreadCrumbArr: (breadCrumbArgs) => {

        const breadCrumb:BreadCrumbType[] = [
            getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, '',  "/app/named-services"),
            { label: strings.NAV_NAMED_SERVICE_ITEM, url: "/app/named-services" },
            { label: strings.ADD_NAMED_SERVICE, url: '' }
        ]
        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {

        // FIXME Here we need to define prevPageUrl from action args
        let prevPageUrl = "/app/named-services"
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.ADD_TAG,
                icon: faAdd,
                key: TAB_ADD_TAG,
                action: (id) => detailPageNav({ navigate: navigate, tab: TAB_ADD_TAG, id: null, row: null, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url:  `/rest/named-services?get_schema=post,dassui`,
                    backUrl: prevPageUrl
                },
                EditObject: null
            },
        ]
        return actions;
    }
}



export const NamedServicesRowProps: DetailPagePropsType = {
    getBreadCrumbArr: (breadCrumbArgs: any) => {
        const { id } = breadCrumbArgs;
        // let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';
        let prevPageUrl = "/app/named-services"
        const breadCrumb:BreadCrumbType[] = [
            getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, '',  prevPageUrl),
            { label: strings.NAV_NAMED_SERVICE_ITEM, url: prevPageUrl },
            { label: `${id}`, url: '', comment: '' }
        ]
        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {
        let prevPageUrl = "/app/named-services"
        let row = actionArgs.hasOwnProperty('row') ? actionArgs['row'] : {}; 
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.EDIT_TAG,
                icon: faAdd,
                key: TAB_EDIT_TAG,
                action: (row: any) => detailPageNav({ navigate: navigate, tab: TAB_EDIT_TAG, id: row.tagid, row: row, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url:  `/rest/named-services?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row.name ? row : null
            }
        ]
        return actions;
    }
}

