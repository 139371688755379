import { ColumnType } from "../../components/Common/DataTable/DataTypes";
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { getDevicesFromDASSService } from "../../services/batch";
import { strings } from "../../services/Localization";

export class DeviceImportHelper {

    // static provisionedDevicesColumnNames = ['name', 'deviceUuid', 'provisionToken', 'url', 'bearerToken', 'username', 'password', 'privateKey', 'certificate'];
    static dassDevicesColumnNames = ['deveui', 'comment', 'device_profile_uuid', 'service_profile_uuid', 'lora_device_class', 'appkey'];
    // static normalDeviceSample = {
    //     "name": "TestDeviceNormal",
    //     "deviceUuid": "fc808b04-cb99-48f8-bd59-d75a5a541215",
    //     "deviceId": "TestDeviceIdNormal",
    //     "description": "TestDeviceDescriptionNormal",
    //     "device_profile_uuid": "81d83d40-ad61-4692-afac-355a5a05bf0b",
    //     "notes": "TestDeviceNotesNormal",
    //     "global_tags": "asdasdas",
    //     "groups": "asdasd",
    //     "suspended": true,
    //     "tokens": ["nZK5BGipDOC1FxieXwL0ghzPUJvHQrPKvg9lEuK1xLHHk5jcnAAx"],
    //     "parameters": {},
    //     "latitude": 17.978733,
    //     "longitude": 72.778054,
    //     "provisionToken": "3zXli7t2lXrTG5H6UP9PJLnSO8Di2s96zN0c9T4JtBO21s2aPIjDv",
    //     "connection_uuid": "1e070787-ff39-4f0b-9c2e-332d25807cd3",
    //     "tags": { "prop1": "prop1value" }
    // }
    // sample csv records for normal devices
    // name,deviceId,description,deviceUuid,provisionToken,device_profile_uuid,connection_uuid,bearerToken,username,password,privateKey,certificate,notes,global_tags,groups,suspended,tokens,parameters,latitude,longitude,tags
    // TestDeviceNormal,TestDeviceIdNormal,TestDeviceDescriptionNormal,fc808b04-cb99-48f8-bd59-d75a5a541215,3zXli7t2lXrTG5H6UP9PJLnSO8Di2s96zN0c9T4JtBO21s2aPIjDv,81d83d40-ad61-4692-afac-355a5a05bf0b,1e070787-ff39-4f0b-9c2e-332d25807cd3,3zXli7t2lXrTG5H6UP9PJLnSO8Di2s96zN0c9T4JtBO21s2aPIjDv,username,password,privateKey,certificate,TestDeviceNotesNormal,asdasdas,asdasd,true,nZK5BGipDOC1FxieXwL0ghzPUJvHQrPKvg9lEuK1xLHHk5jcnAAx,{},17.978733,72.778054,{"prop1":"prop1value"}
    // TestDeviceNormal2,TestDeviceIdNormal2,TestDeviceDescriptionNormal2,fc808b04-cb99-48f8-bd59-d75a5a541216,3zXli7t2lXrTG5H6UP9PJLnSO8Di2s96zN0c9T4JtBO21s2aPIjDv,81d83d40-ad61-4692-afac-355a5a05bf0b,1e070787-ff39-4f0b-9c2e-332d25807cd3,3zXli7t2lXrTG5H6UP9PJLnSO8Di2s96zN0c9T4JtBO21s2aPIjDv,username,password,privateKey,certificate,TestDeviceNotesNormal2,asdasdas,asdasd,true,nZK5BGipDOC1FxieXwL0ghzPUJvHQrPKvg9lEuK1xLHHk5jcnAAx,{},17.978733,72.778054,{"prop1":"prop1value"}
    static normalDevicesColumnNames = [
        'name', 'deviceId', 'description', 
        'deviceUuid', 'provisionToken', 'device_profile_uuid', 'connection_uuid',
        'bearerToken', 'username', 'password', 'privateKey', 'certificate',
        'notes', 'global_tags', 'groups', 'applications',
        'suspended', 'tokens',  'latitude', 'longitude', 
        // 'tags','parameters',
        // 'device_profile_name', 'connection_name'
    ];

    static mandatoryColumns = {
        'dassDevices': ['deveui', 'device_profile_uuid', 'service_profile_uuid', 'lora_device_class'],

        'normalDevices': [
            ['deviceUuid', 'provisionToken'], 
            // ['deviceUuid', 'provisionToken', 'certificate'], // certificate is provided
            // ['deviceUuid', 'provisionToken', 'bearerToken'], // bearerToken is provided
            // ['deviceUuid', 'provisionToken', 'username', 'password'], // username and password is provided
            ['name', ]
        ],
    }

    static searchDevicesWithPagination = async () => {
        try {
            // const res = await GenericDassQuery(`/rest/nodes?orbiwan_instance=${owInstance}&page=${page}&pageSize=${pageSize}`, { method: "GET" });
            const url = '/rest/nodes?all=true&limit=50&stream=progress&get_pages=true&sort_by_name=asc';
            const res = await GenericDassQuery(url, { method: "GET" });
            // the response would be - 
            // {"total":90,"per_page":50,"pages":[{"page_state":"asdasdasd=="}, {page_state:"as342dsd=="}]}
            if (res.data.pages.length > 0) {
                const devicesList = [];

                for (let i = 0; i < res.data.pages.length; i++) {
                    const pageState = res.data.pages[i].page_state;
                    const urlPage = `/rest/nodes?page_state=${pageState}&all=true&limit=50&stream=progress&sort_by_name=asc`;
                    const resPage = await GenericDassQuery(urlPage, { method: "GET" });
                    // the response would be -
                    // [{dev1},{dev2}]
                    const tempDevices = resPage.data;
                    for (let j = 0; j < tempDevices.length; j++) {
                        devicesList.push(tempDevices[j]);
                    }
                }
                return { error: false, data: devicesList };
            } else {
                return { error: true, data: [], message: 'No devices found' };
            }
        } catch (error) {
            console.log(`Error getting devices from BE - `, error);
            // throw error;
            return { error: true, data: [], message: `Error getting devices: ${error.message}` };
        }
    }

    static getConnectionsWithPagination = async () => {
        try {
            // const res = await GenericDassQuery(`/rest/connections?orbiwan_instance=${owInstance}&page=${page}&pageSize=${pageSize}`, { method: "GET" });
            const url = '/rest/connections?all=true&limit=50&stream=progress&get_pages=true&sort_by_name=asc';
            const res = await GenericDassQuery(url, { method: "GET" });
            // the response would be - 
            // {"total":90,"per_page":50,"pages":[{"page_state":"asdasdasd=="}, {page_state:"as342dsd=="}]}
            if (res.data.pages.length > 0) {
                const connectionsList = [];

                for (let i = 0; i < res.data.pages.length; i++) {
                    const pageState = res.data.pages[i].page_state;
                    const urlPage = `/rest/connections?page_state=${pageState}&all=true&limit=50&stream=progress&sort_by_name=asc`;
                    const resPage = await GenericDassQuery(urlPage, { method: "GET" });
                    // the response would be -
                    // [{dev1},{dev2}]
                    const tempObj = resPage.data;
                    for (let j = 0; j < tempObj.length; j++) {
                        tempObj[j].connection_uuid = tempObj[j].connection_uuid || tempObj[j].profile_uuid;
                        connectionsList.push(tempObj[j]);
                    }
                }
                return { error: false, data: connectionsList };
            } else {
                return { error: true, data: [], message: 'No devices found' };
            }
        } catch (error) {
            console.log(`Error getting connections list -`, error);
            // throw error;
            return { error: true, data: [], message: `Error getting connections: ${error.message}` };
        }
    }

    static getDeviceProfilesWithPagination = async () => {
        try {
            const url = '/rest/device-profiles?all=true&limit=50&stream=progress&get_pages=true&sort_by_name=asc';
            const res = await GenericDassQuery(url, { method: "GET" });
            // the response would be - 
            // {"total":90,"per_page":50,"pages":[{"page_state":"asdasdasd=="}, {page_state:"as342dsd=="}]}
            if (res.data.pages.length > 0) {
                const deviceProfilesList = [];

                for (let i = 0; i < res.data.pages.length; i++) {
                    const pageState = res.data.pages[i].page_state;
                    const urlPage = `/rest/device-profiles?page_state=${pageState}&all=true&limit=50&stream=progress&sort_by_name=asc`;
                    const resPage = await GenericDassQuery(urlPage, { method: "GET" });
                    // the response would be -
                    // [{dev1},{dev2}]
                    const tempObj = resPage.data;
                    for (let j = 0; j < tempObj.length; j++) {
                        const profileContent = typeof tempObj[i].profileContent === 'string' ? JSON.parse(tempObj[i].profileContent) : tempObj[i].profileContent;
                        tempObj[i].profileContent = profileContent;
                        deviceProfilesList.push(tempObj[j]);
                    }
                }
                return { error: false, data: deviceProfilesList };
            } else {
                return { error: true, data: [], message: 'No devices found' };
            }
        } catch (error) {
            console.log(`Error getting deviceProfiles list -`, error);
            // throw error;
            return { error: true, data: [], message: `Error getting deviceProfiles: ${error.message}` };
        }
    }

    static getDevicesFromDass = async (owInstance) => {
        // call the service to get the devices from the selected orbiwan instance
        try {
            const res = await getDevicesFromDASSService({ orbiwan_instance: owInstance });
            const devicesList = res.data;
            const data = [];
            const columnsHeader = ['deveui', 'comment', 'tags', 'appkey', 'applications', 'device_profile_uuid', 'registration_status', 'service_profile_uuid', 'dmp_device_profile_uuid', 'lora_device_class'];
            for (let i = 0; i < devicesList.length; i++) {
                const row = devicesList[i];
                const rowObj = {};
                for (let j = 0; j < columnsHeader.length; j++) {
                    if (columnsHeader[j] === 'lora_device_class') {
                        rowObj[columnsHeader[j]] = DeviceImportHelper.lora_classes[row[columnsHeader[j]]] || '';
                    } else {
                        rowObj[columnsHeader[j]] = row[columnsHeader[j]] || '';
                    }
                }
                // rowObj['appkey'] = row['appkey'] || undefined;
                data.push(rowObj);
            }
            return {
                rows: data,
                columnsHeader: columnsHeader
            };
        } catch (error) {
            console.log(`Error getting devices from DASS`, error);
            throw error;
        }
    }

    static saveDevice = async (device) => {
        // call /rest/devices with POST to save the device
        try {
            const res = await GenericDassQuery('/rest/devices', { method: "POST", data: device });
            return {
                error: false,
                ...res
            };
        } catch (error) {
            console.log(`Error saving device`, error);
            return { error: true, message: `Error saving device: ${error.message}` };
            // throw error;
        }
    }

    static searchDevices = async (searchInput) => {
        // call the service to search for devices
        // /rest/devices?

        let query = '';
        for (let key in searchInput) {
            query += `search_${key}=${searchInput[key]}&`;
        }

        const res = await GenericDassQuery(`/rest/nodes?${query}`, { method: "GET" });
        return res.data;
    }

    static getDASSServiceProfiles = async (owInstance) => {
        // url to call
        // /rest/lns/device-profiles?orbiwan_instance=
        const url = `/rest/lns/service-profiles?orbiwan_instance=${owInstance}`;
        const response = await GenericDassQuery(url, { method: "GET" });
        const responseJSON = response.data;
        return responseJSON;
    }

    static getDASSDeviceProfiles = async (owInstance) => {
        // url to call
        // /rest/lns/device-profiles?orbiwan_instance=
        const url = `/rest/lns/device-profiles?orbiwan_instance=${owInstance}`;
        const response = await GenericDassQuery(url, { method: "GET" });
        alert('Dass Service profile response');
        const responseJSON = response.data;
        return responseJSON;
    }

    static sanitizeValue = (value) => {
        // sanitize for quoted values, unsafe values etc
        const sanitizedValue = value.replace(/"/g, '');
        return sanitizedValue;
    }

    static lora_classes = {
        '0': 'A',
        '1': 'B',
        '2': 'C'
    }

    // pre registered columns: deviceUuid, provisionToken, url, bearerToken
    static provisionedDevicesColumns: ColumnType[] = [
        {
            key: 'bulk_action_checkbox',
            type: "bulk_action_checkbox",
            title: strings.BULK_ACTIONS,
            filterable: false,
            cellWidth: 2,
            newCellWidth: "2%"
        },
        // already in dmp
        {
            key: 'already_in_dmp',
            type: "text",
            title: strings.IN_DMP,
            sortable: true,
            sortKey: 'already_in_dmp',
            cellWidth: 2,
            newCellWidth: "2%",
            htmlRender: (row) => {
                return row.already_in_dmp ? strings.YES : strings.NO;
            }
        },
        {
            key: 'deviceUuid',
            type: "text",
            title: strings.DEVICE_UUID,
            sortable: true,
            sortKey: 'deviceUuid',
            newCellWidth: '150px'
        },
        {
            key: 'provisionToken',
            type: "text",
            title: strings.PROVISION_TOKEN,
            sortable: true,
            sortKey: 'provisionToken',
            newCellWidth: '150px'
        },
        {
            key: 'dmp_device_profile_uuid',
            type: "text",
            title: strings.DEVICE_PROFILE,
            sortable: true,
            sortKey: 'dmp_device_profile_uuid',
            newCellWidth: '150px',
            htmlRender: (row) => {
                return row.dmp_device_profile_uuid ? `${row.dmp_device_profile_name}<br/>(${row.dmp_device_profile_uuid})` : `${row.dmp_device_profile_name}`;
            }
        },
        {
            key: 'dmp_connection_uuid',
            type: "text",
            title: strings.CONNECTION,
            sortable: true,
            sortKey: 'dmp_connection_uuid',
            newCellWidth: '150px',
            htmlRender: (row) => {
                return row.dmp_connection_uuid ? `${row.dmp_connection_name}<br/>(${row.dmp_connection_uuid})` : `${row.dmp_connection_name}`;
            },
        }
    ];

    static normalDevicesColumns: ColumnType[] = [
        {
            key: 'bulk_action_checkbox',
            type: "bulk_action_checkbox",
            title: strings.BULK_ACTIONS,
            filterable: false,
            cellWidth: 2,
            newCellWidth: "2%"
        },
        // already in dmp
        {
            key: 'already_in_dmp',
            type: "text",
            title: strings.IN_DMP,
            sortable: true,
            sortKey: 'already_in_dmp',
            cellWidth: 2,
            newCellWidth: "2%",
            htmlRender: (row) => {
                return row.already_in_dmp ? strings.YES : strings.NO;
            }
        },
        {
            key: 'name',
            type: "text",
            title: strings.DEVICE_NAME,
            sortable: true,
            sortKey: 'name',
            newCellWidth: '150px'
        },
        {
            key: 'dmp_device_profile_uuid',
            type: "text",
            title: strings.DEVICE_PROFILE,
            sortable: true,
            sortKey: 'dmp_device_profile_uuid',
            newCellWidth: '150px',
            htmlRender: (row) => {
                return row.dmp_device_profile_uuid ? `${row.dmp_device_profile_name}<br/>(${row.dmp_device_profile_uuid})` : `${row.dmp_device_profile_name}`;
            }
        },
        {
            key: 'dmp_connection_uuid',
            type: "text",
            title: strings.CONNECTION,
            sortable: true,
            sortKey: 'dmp_connection_uuid',
            newCellWidth: '150px',
            htmlRender: (row) => {
                return row.dmp_connection_uuid ? `${row.dmp_connection_name}<br/>(${row.dmp_connection_uuid})` : `${row.dmp_connection_name}`;
            },
        }
    ];

    static dassDeviceColumns: ColumnType[] = [
        {
            key: 'bulk_action_checkbox',
            type: "bulk_action_checkbox",
            title: strings.BULK_ACTIONS,
            filterable: false,
            cellWidth: 2,
            newCellWidth: "2%"
        },
        {
            key: 'already_in_dmp',
            type: "text",
            title: strings.IN_DMP,
            sortable: true,
            sortKey: 'already_in_dmp',
            cellWidth: 2,
            newCellWidth: "2%",
            htmlRender: (row) => {
                return row.already_in_dmp ? strings.YES : strings.NO;
            }
        },
        {
            key: 'deveui',
            type: "text",
            title: strings.DEVEUI,
            sortable: true,
            sortKey: 'deveui',
            newCellWidth: '100px'
        },
        {
            key: 'comment',
            type: "text",
            title: strings.COMMENT,
            sortable: true,
            sortKey: 'comment',
            newCellWidth: '150px'
        },
        {
            key: 'lora_device_class',
            type: "text",
            title: strings.LORA_DEVICE_CLASS,
            sortable: false,
            sortKey: 'lora_device_class',
            newCellWidth: '50px',
        },
        {
            key: 'dass_device_profile_name',
            type: "text",
            title: strings.LNS_DEVICE_PROFILE,
            sortable: true,
            sortKey: 'dass_device_profile_name',
            newCellWidth: '150px',
            htmlRender: (row) => {
                return row.device_profile_uuid ? `${row.dass_device_profile_name}<br/>(${row.device_profile_uuid})` : ``;
            }
        },
        {
            key: 'dass_service_profile_name',
            type: "text",
            title: strings.LNS_SERVICE_PROFILE,
            sortable: true,
            sortKey: 'dass_service_profile_name',
            newCellWidth: '150px',
            htmlRender: (row) => {
                return row.service_profile_uuid ? `${row.dass_service_profile_name}<br/>(${row.service_profile_uuid})` : ``;
            }
        },
        {
            key: 'dmp_device_profile_uuid',
            type: "text",
            title: strings.DEVICE_PROFILE,
            sortable: true,
            sortKey: 'dmp_device_profile_uuid',
            newCellWidth: '150px',
            htmlRender: (row) => {
                return row.dmp_device_profile_uuid ? `${row.dmp_device_profile_name}<br/>(${row.dmp_device_profile_uuid})` : `${row.dmp_device_profile_name}`;
            }
        },
        // status column, after registration
        // {
        //     key: 'registration_status',
        //     type: "text",
        //     title: strings.REGISTRATION_STATUS,
        //     sortable: true,
        //     sortKey: 'registration_status',
        //     newCellWidth: '150px',
        //     htmlRender: (row) => {
        //         return row.registration_status ? `${row.registration_status}` : ``;
        //     }
        // }
    ];

}