import { IJsonSchemaObject } from "../UiJsonSchemaTypes";
import { Value, VegaLikeSchemaBase, vegaLikeJsonSchema } from "./VegaLikeToolsTypes";


export interface TableSchema extends VegaLikeSchemaBase {
    $schema: "internal:table";

    data: {
        meta: Array<{
            name: string;
            type: string;
        }>;
        values: any[];
    };
   
    footer?: Value;

    // To trigger a download, set the filename and increase. Download happen on cnt > 0
    downloadFilename?: string;
    downloadCnt?: number;
}






export const tableJsonSchema: IJsonSchemaObject = {
	$id: "internal:table",
	$schema: "http://json-schema.org/draft-07/schema",
	type: "object",
    $defs: { ...vegaLikeJsonSchema.$defs },
    properties: {
        ...vegaLikeJsonSchema.properties,

        footer: {
            description: `Optional footer text to be shown below table`,
            $ref: "#/$defs/numberStringRefExprDef",
        },

	},
	required: ["$schema"],
    additionalProperties: false,
}
